import React, { useState, useEffect } from "react";
import "./SingleImgDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import video1 from "../../Assets/CategoriesImg/product cards/video.mp4";
import { GoShieldCheck } from "react-icons/go";

// img
import card1 from "../../Assets/CategoriesImg/product cards/Untitled-1 (1).png";
import card2 from "../../Assets/CategoriesImg/product cards/Untitled-1.png";
import card3 from "../../Assets/CategoriesImg/product cards/Untitled-2.png";
import card4 from "../../Assets/CategoriesImg/product cards/Untitled-3.png";
import card5 from "../../Assets/CategoriesImg/product cards/Untitled-4.png";
import card6 from "../../Assets/CategoriesImg/product cards/Untitled-5.png";
import card7 from "../../Assets/CategoriesImg/product cards/Untitled-6.png";

function SingleImgDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { item } = location.state;

  console.log(item);

  const navigate = useNavigate();

  const [showForm, setShowForm] = useState(false); // State to track form visibility
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailId: "",
    address: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted: ", formData);
    // Handle form submission here (e.g., send data to a server)
  };

  return (
    <div className="SingleImgDetails">
      <div className="row mt-5">
        <div className="img col-sm-12 col-md-6">
          <img src={item.image} alt={item.name} className="img-fluid" />
        </div>
        <div className="details col-sm-12 col-md-6">
          <div>
            <h2>{item.name}</h2>
            <br />
            {item.sku.length > 0 && <p>SKU: {item.sku}</p>}
            <br />
            <p>{item.details}</p>
            <br />
            {console.log(item?.size)}{" "}
            {item?.size &&
              item?.size?.length > 0 &&
              item?.size[0].length > 0 && (
                <div>
                  <p>Size:-</p>
                  {item.size.map((check, index) => (
                    <p key={index}>{check}</p>
                  ))}
                </div>
              )}
            <div className="mt-5">
              {" "}
              <span>
                <GoShieldCheck size={22} />
                {"   "}
              </span>
              1 year Warranty
            </div>
            <div className="inquiryCheck mt-3">
              <button
                className="checkThisItem btn btn-danger"
                onClick={() => navigate("/contact")}
              >
                Submit Inquiry
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="video">
        <iframe
          width="1000"
          height="315"
          src={video1}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> */}

      {showForm && (
        <div className="form-outer" onClick={() => setShowForm(false)}>
          {" "}
          <div
            className="inquiryForm mt-3"
            onClick={(e) => e.stopPropagation()}
          >
            <form onSubmit={handleSubmit}>
              <div className="input-div">
                <div className="labeldiv">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="labeldiv">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="input-div">
                <div className="labeldiv">
                  <label>Phone Number:</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="labeldiv">
                  <label>Email ID:</label>
                  <input
                    type="email"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="input-div">
                <div className="labeldiv">
                  <label style={{ top: "-18%", left: "3%" }}>Address:</label>
                  <textarea
                    name="address"
                    rows="2"
                    cols="90"
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="mt-5 cardproduct">
        {/* <h1>Related Products</h1> */}
        <div className="row">
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card1} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card2} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card3} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card4} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card5} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card6} alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="mt-5">
        <video
          width="100%"
          height="400"
          autoPlay
          muted
          loop
          style={{ objectFit: "cover" }}
        >
          <source src={video1} type="video/mp4" style={{ width: "100%" }} />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default SingleImgDetails;
