// offce furniture cabin desk
import exicutiveDesktop1 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk1.webp";
import exicutiveDesktop2 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk2.webp";
import exicutiveDesktop3 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk3.avif";
import exicutiveDesktop4 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk4.avif";
import exicutiveDesktop5 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk5.webp";
import exicutiveDesktop6 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk7.webp";
import exicutiveDesktop8 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk8.webp";
import exicutiveDesktop9 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk10.webp";
import exicutiveDesktop10 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk11.webp";
import exicutiveDesktop11 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk12.webp";
import exicutiveDesktop12 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk13.webp";
import exicutiveDesktop13 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk14.webp";
import exicutiveDesktop14 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk15.webp";
import exicutiveDesktop15 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk17.webp";
import exicutiveDesktop16 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk18.webp";
import exicutiveDesktop17 from "../../Assets/CategoriesImg/office furniture/exicutive cabin desk/desk19.webp";

// offce furniture standard table
import standardtable1 from "../../Assets/CategoriesImg/office furniture/standard table/table1.webp";
import standardtable2 from "../../Assets/CategoriesImg/office furniture/standard table/table2.webp";
import standardtable3 from "../../Assets/CategoriesImg/office furniture/standard table/table3.webp";
import standardtable4 from "../../Assets/CategoriesImg/office furniture/standard table/table4.avif";
import standardtable5 from "../../Assets/CategoriesImg/office furniture/standard table/table5.webp";

// office conference and meeting table
import conferenceTable1 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf1.webp";
import conferenceTable2 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf2.webp";
import conferenceTable3 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf3.jpg";
import conferenceTable4 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf4.webp";
import conferenceTable5 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf5.webp";
import conferenceTable6 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf6.avif";
import conferenceTable7 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf7.webp";
import conferenceTable8 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf8.webp";
import conferenceTable9 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf10.webp";
import conferenceTable10 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf11.webp";
import conferenceTable11 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf12.webp";
import conferenceTable12 from "../../Assets/CategoriesImg/office furniture/conference-and-meeting-tables/conf14.webp";

// work station open desk

import openDesk1 from "../../Assets/CategoriesImg/office furniture/workstations-open-desk/WS1.webp";
import openDesk2 from "../../Assets/CategoriesImg/office furniture/workstations-open-desk/ws2.webp";
import openDesk3 from "../../Assets/CategoriesImg/office furniture/workstations-open-desk/ws3.webp";
import openDesk4 from "../../Assets/CategoriesImg/office furniture/workstations-open-desk/ws4.webp";
import openDesk5 from "../../Assets/CategoriesImg/office furniture/workstations-open-desk/ws5.webp";

// work stati0n cubicless

import cubicless1 from "../../Assets/CategoriesImg/office furniture/workstations-cubicles/wsc1.webp";
import cubicless2 from "../../Assets/CategoriesImg/office furniture/workstations-cubicles/wsc2.webp";
import cubicless3 from "../../Assets/CategoriesImg/office furniture/workstations-cubicles/wsc3.webp";
import cubicless4 from "../../Assets/CategoriesImg/office furniture/workstations-cubicles/wsc4.jpg";
import cubicless5 from "../../Assets/CategoriesImg/office furniture/workstations-cubicles/wsc5.webp";

// receptin counter

import reccou1 from "../../Assets/CategoriesImg/office furniture/reception-counters/recc1.webp";
import reccou2 from "../../Assets/CategoriesImg/office furniture/reception-counters/recc2.webp";
import reccou3 from "../../Assets/CategoriesImg/office furniture/reception-counters/recc3.webp";
import reccou4 from "../../Assets/CategoriesImg/office furniture/reception-counters/recc4.webp";
import reccou5 from "../../Assets/CategoriesImg/office furniture/reception-counters/recc5.webp";
import reccou6 from "../../Assets/CategoriesImg/office furniture/reception-counters/recc6.webp";

// storages cabinets
import cabinets1 from "../../Assets/CategoriesImg/office furniture/storage-cabinets/sc1.webp";
import cabinets2 from "../../Assets/CategoriesImg/office furniture/storage-cabinets/sc2.webp";
import cabinets3 from "../../Assets/CategoriesImg/office furniture/storage-cabinets/sc3.webp";
import cabinets4 from "../../Assets/CategoriesImg/office furniture/storage-cabinets/sc4.webp";
import cabinets5 from "../../Assets/CategoriesImg/office furniture/storage-cabinets/sc5.webp";
import cabinets6 from "../../Assets/CategoriesImg/office furniture/storage-cabinets/sc6.webp";

//  Metal Almirah
import MetalAlmirah1 from "../../Assets/CategoriesImg/metal-storage/metal-almirah/alm1.webp";
import MetalAlmirah2 from "../../Assets/CategoriesImg/metal-storage/metal-almirah/alm2.webp";
import MetalAlmirah3 from "../../Assets/CategoriesImg/metal-storage/metal-almirah/alm2.webp";

import locker1 from "../../Assets/CategoriesImg/metal-storage/lokeres/lok1.webp";
import locker2 from "../../Assets/CategoriesImg/metal-storage/lokeres/lok2.webp";
import locker3 from "../../Assets/CategoriesImg/metal-storage/lokeres/lok3.webp";
import locker4 from "../../Assets/CategoriesImg/metal-storage/lokeres/lok4.webp";

import sl1 from "../../Assets/CategoriesImg/metal-storage/book-shelves/sl1.webp";
import sl2 from "../../Assets/CategoriesImg/metal-storage/book-shelves/sl2.webp";
import sl3 from "../../Assets/CategoriesImg/metal-storage/book-shelves/sl3.webp";
import sl4 from "../../Assets/CategoriesImg/metal-storage/book-shelves/sl4.webp";
import sl5 from "../../Assets/CategoriesImg/metal-storage/book-shelves/sl5.webp";

// officeChairSofas
import chair1 from "../../Assets/CategoriesImg/office chair & sofa/chair1.webp";
import chair2 from "../../Assets/CategoriesImg/office chair & sofa/chair2.webp";
import chair3 from "../../Assets/CategoriesImg/office chair & sofa/chair3.webp";
import chair4 from "../../Assets/CategoriesImg/office chair & sofa/chair4.webp";
import chair5 from "../../Assets/CategoriesImg/office chair & sofa/chair5.webp";
import chair6 from "../../Assets/CategoriesImg/office chair & sofa/chair6.webp";
import chair7 from "../../Assets/CategoriesImg/office chair & sofa/chair7.webp";
import chair8 from "../../Assets/CategoriesImg/office chair & sofa/chair8.webp";
import chair9 from "../../Assets/CategoriesImg/office chair & sofa/chair9.webp";
import chair10 from "../../Assets/CategoriesImg/office chair & sofa/chair10.webp";
import chair11 from "../../Assets/CategoriesImg/office chair & sofa/chiar11.webp";
import chair12 from "../../Assets/CategoriesImg/office chair & sofa/chiar12.webp";
import chair13 from "../../Assets/CategoriesImg/office chair & sofa/chiar13.webp";
import chair14 from "../../Assets/CategoriesImg/office chair & sofa/chiar14.webp";
import chair15 from "../../Assets/CategoriesImg/office chair & sofa/chair15.webp";
import chair16 from "../../Assets/CategoriesImg/office chair & sofa/chair16.webp";
import chair17 from "../../Assets/CategoriesImg/office chair & sofa/chair17.webp";
import chair18 from "../../Assets/CategoriesImg/office chair & sofa/chair19.webp";
import chair19 from "../../Assets/CategoriesImg/office chair & sofa/chair20.webp";
import chair20 from "../../Assets/CategoriesImg/office chair & sofa/chair21.webp";
import chair21 from "../../Assets/CategoriesImg/office chair & sofa/chair22.webp";
import chair22 from "../../Assets/CategoriesImg/office chair & sofa/chiar23.webp";
import chair23 from "../../Assets/CategoriesImg/office chair & sofa/chair24.webp";
import chair24 from "../../Assets/CategoriesImg/office chair & sofa/chair25.webp";
import chair25 from "../../Assets/CategoriesImg/office chair & sofa/chair26.webp";
import chair26 from "../../Assets/CategoriesImg/office chair & sofa/chair27.webp";
import chair27 from "../../Assets/CategoriesImg/office chair & sofa/chiar28.webp";
import chair28 from "../../Assets/CategoriesImg/office chair & sofa/chair29.webp";
import chair29 from "../../Assets/CategoriesImg/office chair & sofa/chair30.webp";
import chair30 from "../../Assets/CategoriesImg/office chair & sofa/chair31.webp";
import chair31 from "../../Assets/CategoriesImg/office chair & sofa/chair32.webp";
import chair32 from "../../Assets/CategoriesImg/office chair & sofa/chair33.webp";
import chair33 from "../../Assets/CategoriesImg/office chair & sofa/chair34.webp";
import chair34 from "../../Assets/CategoriesImg/office chair & sofa/chair35.webp";
import chair35 from "../../Assets/CategoriesImg/office chair & sofa/chair36.webp";
import chair36 from "../../Assets/CategoriesImg/office chair & sofa/chiar37.webp";
import chair37 from "../../Assets/CategoriesImg/office chair & sofa/chair38.webp";
import chair38 from "../../Assets/CategoriesImg/office chair & sofa/chair39.webp";
import chair39 from "../../Assets/CategoriesImg/office chair & sofa/chair40.webp";
import chair40 from "../../Assets/CategoriesImg/office chair & sofa/chair41.webp";
import chair41 from "../../Assets/CategoriesImg/office chair & sofa/chair42.webp";
import chair42 from "../../Assets/CategoriesImg/office chair & sofa/chair43.webp";
import chair43 from "../../Assets/CategoriesImg/office chair & sofa/chiar44.webp";
import chair44 from "../../Assets/CategoriesImg/office chair & sofa/chair45.webp";
import chair45 from "../../Assets/CategoriesImg/office chair & sofa/chiar46.webp";
import chair46 from "../../Assets/CategoriesImg/office chair & sofa/chair47.webp";
import chair47 from "../../Assets/CategoriesImg/office chair & sofa/chair48.webp";
import chair48 from "../../Assets/CategoriesImg/office chair & sofa/chair49.webp";
import chair49 from "../../Assets/CategoriesImg/office chair & sofa/chair50.webp";
import chair50 from "../../Assets/CategoriesImg/office chair & sofa/chair51.webp";
import chair51 from "../../Assets/CategoriesImg/office chair & sofa/chair52.webp";
import chair52 from "../../Assets/CategoriesImg/office chair & sofa/chair53.webp";
import chair53 from "../../Assets/CategoriesImg/office chair & sofa/chair54.webp";
import chair54 from "../../Assets/CategoriesImg/office chair & sofa/chair55.webp";
import chair55 from "../../Assets/CategoriesImg/office chair & sofa/chair56.webp";
import chair56 from "../../Assets/CategoriesImg/office chair & sofa/chair57.webp";
import chair57 from "../../Assets/CategoriesImg/office chair & sofa/chair58.webp";
import chair58 from "../../Assets/CategoriesImg/office chair & sofa/chair59.webp";
import chair59 from "../../Assets/CategoriesImg/office chair & sofa/chair60.webp";
import chair60 from "../../Assets/CategoriesImg/office chair & sofa/chair61.webp";
import chair61 from "../../Assets/CategoriesImg/office chair & sofa/chair62.webp";
import chair62 from "../../Assets/CategoriesImg/office chair & sofa/chair63.webp";
import chair63 from "../../Assets/CategoriesImg/office chair & sofa/chair64.webp";
import chair64 from "../../Assets/CategoriesImg/office chair & sofa/sofa65.webp";
import chair65 from "../../Assets/CategoriesImg/office chair & sofa/sofa66.webp";
import chair66 from "../../Assets/CategoriesImg/office chair & sofa/sofa67.webp";
import chair67 from "../../Assets/CategoriesImg/office chair & sofa/sofa68.webp";
import chair68 from "../../Assets/CategoriesImg/office chair & sofa/sofa69.webp";
import chair69 from "../../Assets/CategoriesImg/office chair & sofa/sofa70.webp";
import chair70 from "../../Assets/CategoriesImg/office chair & sofa/sofa71.webp";
import chair71 from "../../Assets/CategoriesImg/office chair & sofa/sofa72.webp";
import chair72 from "../../Assets/CategoriesImg/office chair & sofa/sofa73.webp";

// compactors
import compactor1 from "../../Assets/CategoriesImg/compactors/compt1.webp";
import compactor2 from "../../Assets/CategoriesImg/compactors/compr2.webp";
import compactor3 from "../../Assets/CategoriesImg/compactors/coomp3.webp";

// institutionalFurniture

import institutional1 from "../../Assets/CategoriesImg/institutional-furniture/smart-desks-dual-desks-1/DAKSHModelNo-DK01.avif";
import computerlab1 from "../../Assets/CategoriesImg/institutional-furniture/computer-lab-and-library-desks/ll1.webp";
import computerlab2 from "../../Assets/CategoriesImg/institutional-furniture/computer-lab-and-library-desks/ll2.avif";
import computerlab3 from "../../Assets/CategoriesImg/institutional-furniture/computer-lab-and-library-desks/ll3.avif";
import computerlab4 from "../../Assets/CategoriesImg/institutional-furniture/computer-lab-and-library-desks/ll4.avif";

// import conteen and cafearia
import conteen1 from "../../Assets/CategoriesImg/institutional-furniture/canteen-and-cafeteria-furniture/cnca1.webp";
import conteen2 from "../../Assets/CategoriesImg/institutional-furniture/canteen-and-cafeteria-furniture/caca2.webp";
import conteen3 from "../../Assets/CategoriesImg/institutional-furniture/canteen-and-cafeteria-furniture/caca3.webp";
import conteen4 from "../../Assets/CategoriesImg/institutional-furniture/canteen-and-cafeteria-furniture/caca4.webp";

// import library raks
import library1 from "../../Assets/CategoriesImg/institutional-furniture/library-racks/TF-RAC01.webp";
import library2 from "../../Assets/CategoriesImg/institutional-furniture/library-racks/TF-RAC02.webp";
import library3 from "../../Assets/CategoriesImg/institutional-furniture/library-racks/TF-RAC03.webp";
import library4 from "../../Assets/CategoriesImg/institutional-furniture/library-racks/TF-RAC04.webp";
import library5 from "../../Assets/CategoriesImg/institutional-furniture/library-racks/TF-RAC05.webp";

// computer table
import computerTable1 from "../../Assets/CategoriesImg/institutional-furniture/computer-tables/TF-IF-9028.webp";
import computerTable2 from "../../Assets/CategoriesImg/institutional-furniture/computer-tables/TF-IF-9029.webp";
import computerTable3 from "../../Assets/CategoriesImg/institutional-furniture/computer-tables/TF-IF-9030.webp";
import computerTable4 from "../../Assets/CategoriesImg/institutional-furniture/computer-tables/TF-IF-9031.webp";
import computerTable5 from "../../Assets/CategoriesImg/institutional-furniture/computer-tables/TF-IF-9032.webp";

// podium
import podium1 from "../../Assets/CategoriesImg/institutional-furniture/podium/TF-Podium-001.webp";
import podium2 from "../../Assets/CategoriesImg/institutional-furniture/podium/TF-Podium-002.webp";
import podium3 from "../../Assets/CategoriesImg/institutional-furniture/podium/TF-Podium-003.webp";

///////////hotel and hostel furniture
import hotelhostel1 from "../../Assets/CategoriesImg/hotel and hostal furniture/hotel1.webp";
import hotelhostel2 from "../../Assets/CategoriesImg/hotel and hostal furniture/hotel2.webp";
import hotelhostel3 from "../../Assets/CategoriesImg/hotel and hostal furniture/hotel3.webp";
import hotelhostel4 from "../../Assets/CategoriesImg/hotel and hostal furniture/hotel4.webp";
import hotelhostel5 from "../../Assets/CategoriesImg/hotel and hostal furniture/hotel5.webp";
import hotelhostel6 from "../../Assets/CategoriesImg/hotel and hostal furniture/hotel6.webp";
import hotelhostel7 from "../../Assets/CategoriesImg/hotel and hostal furniture/hoel7.webp";

// Laboratory furniture
import slab1 from "../../Assets/CategoriesImg/labolatory furniture/science lab/TF-LAB06.webp";
import slab2 from "../../Assets/CategoriesImg/labolatory furniture/science lab/lab2.webp";
import slab3 from "../../Assets/CategoriesImg/labolatory furniture/science lab/lab3.webp";
import slab4 from "../../Assets/CategoriesImg/labolatory furniture/science lab/lab4.webp";
import slab5 from "../../Assets/CategoriesImg/labolatory furniture/science lab/lab5.webp";
import slab6 from "../../Assets/CategoriesImg/labolatory furniture/science lab/lab6.webp";

// homeFurniture
import homeFurniture1 from "../../Assets/CategoriesImg/home furniture/home1.webp";
import homeFurniture2 from "../../Assets/CategoriesImg/home furniture/home2.webp";
import homeFurniture3 from "../../Assets/CategoriesImg/home furniture/home3.webp";

const Categories = {
  officeFurniture: {
    exicutiveDesk: [
      {
        name: "EXECUTIVE CABIN DESKS WITH METAL LEGS",
        sku: "TFCF-014ML",
        image: exicutiveDesktop1,
        details: "Cabin Furniture with Desk and side storage ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          " Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          " Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },
      {
        name: "EXECUTIVE CABIN DESKS WITH METAL",
        sku: "TFCF-012ML",
        image: exicutiveDesktop2,
        details: "Cabin Furniture with Desk and side storage ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          " Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          " Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },
      {
        name: "EXECUTIVE CABIN DESKS WITH METAL LEGS",
        sku: "TFCF-022ML",
        image: exicutiveDesktop3,
        details: "Cabin Furniture with Desk and side storage ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },
      {
        name: "EXECUTIVE CABIN DESKS WITH METAL LEGS",
        sku: "TFCF-020ML",
        image: exicutiveDesktop4,
        details: "Cabin Furniture with Desk and side storage  ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },
      {
        name: "EXECUTIVE CABIN DESKS WITH METAL LEGS",
        sku: "TFCF-021ML",
        image: exicutiveDesktop5,
        details: "Cabin Furniture with Desk and side storage   ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },
      {
        name: "EXECUTIVE CABIN DESKS WITH METAL LEGS",
        sku: "TFCF-017",
        image: exicutiveDesktop6,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H ",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-002",
        image: exicutiveDesktop8,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-019",
        image: exicutiveDesktop9,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-018",
        image: exicutiveDesktop10,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-009",
        image: exicutiveDesktop11,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1200W x 750D x 750H Return Storage: 900W x 450D x 650H",
          "Table: 1500W x 750D x 750H Return Storage: 900W x 450D x 650H",
          "Table: 1800W x 900D x 750H Return Storage: 900W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-007",
        image: exicutiveDesktop12,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-006",
        image: exicutiveDesktop13,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-005",
        image: exicutiveDesktop14,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-016",
        image: exicutiveDesktop15,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-015",
        image: exicutiveDesktop16,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          "Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          "Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "EXECUTIVE CABIN DESKS",
        sku: "TFCF-010",
        image: exicutiveDesktop17,
        details: "Cabin Furniture with Desk and side storage ",

        size: [
          "Table: 1200W x 750D x 750H Return Storage: 900W x 450D x 650H",
          "Table: 1500W x 750D x 750H Return Storage: 900W x 450D x 650H",
          "Table: 1800W x 900D x 750H Return Storage: 900W x 450D x 650H",
        ],
        URL: "http://",
      },
    ],

    standardTable: [
      {
        name: "STANDARD TABLES",
        sku: " Standard table C",
        image: standardtable1,
        details: "Cabin Furniture with Desk and side storage ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          " Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          " Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "STANDARD TABLES",
        sku: " Standard table B",
        image: standardtable2,
        details: "Cabin Furniture with Desk and side storage ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          " Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          " Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "STANDARD TABLES",
        sku: " Standard table A",
        image: standardtable3,
        details: "Cabin Furniture with Desk and side storage ",
        size: [
          "Table: 1800W x 750D x 750H Return Storage: 1500W x 450D x 650H",
          " Table: 2100W x 900D x 750H Return Storage: 1800W x 450D x 650H",
          " Table: 2400W x 900D x 750H Return Storage: 1800W x 450D x 650H",
        ],
        URL: "http://",
      },

      {
        name: "STANDARD TABLES",
        sku: "TFST-201P",
        image: standardtable4,
        details: "Cabin Furniture ",
        size: [
          "Table: 1200Wx600Dx750H Table: 1500Wx750Dx750H Table: 1800Wx900Dx750H",
        ],
        URL: "http://",
      },

      {
        name: "STANDARD TABLES",
        sku: "TFST-101",
        image: standardtable5,
        details: "Cabin Furniture ",
        size: [
          "Table: 1200Wx600Dx750H",
          "Table: 1500Wx750Dx750H",
          "Table: 1800Wx900Dx750H",
        ],
        URL: "http://",
      },
    ],

    conferenceAndMeetingTable: [
      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-403ML",
        image: conferenceTable1,
        details: "Conference Table ",
        size: [
          "1800W x 1200D x 750H – 6 Person",
          "2400W x 1200D x 750H – 8 Person",
          "3000W x 1500D x 750H – 10 Person",
        ],
        URL: "http://",
      },

      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-410",
        image: conferenceTable2,
        details: "Conference Table ",
        size: [
          "1800W x 1200D x 750H – 6 Person",
          "2400W x 1200D x 750H – 8 Person",
          "3000W x 1500D x 750H – 10 Person",
        ],
        URL: "http://",
      },

      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-441ML",
        image: conferenceTable3,
        details: "Conference Table with Powder Coated MS Legs ",
        size: [
          "1800W x 1200D x 750H – 6 Person",
          "2400W x 1200D x 750H – 8 Person",
          "3000W x 1500D x 750H – 10 Person",
        ],
        URL: "http://",
      },

      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-401ML",
        image: conferenceTable4,
        details: "Conference Table  ",
        size: [
          "1800W x 1200D x 750H – 6 Person",
          "2400W x 1200D x 750H – 8 Person",
          "3000W x 1500D x 750H – 10 Person",
        ],
        URL: "http://",
      },

      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-402ML",
        image: conferenceTable5,
        details: "Conference Table  ",
        size: [
          "1800W x 1200D x 750H – 6 Person",
          "2400W x 1200D x 750H – 8 Person",
          "3000W x 1500D x 750H – 10 Person",
        ],
        URL: "http://",
      },

      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-404",
        image: conferenceTable6,
        details: "Conference Table with Connection Top with Wooden Leg ",
        size: ["1200W x 600D x 750H ", "1500W x 750D x 750H "],
        URL: "http://",
      },

      {
        name: "CONFERENCE AND MEETING TABLES",
        sku: "TFCT-405",
        image: conferenceTable7,
        details: "Conference Table Connection Top with Wooden Leg ",
        size: ["1200W x 600D x 750H ", "1500W x 750D x 750H "],
        URL: "http://",
      },

      {
        name: "MEETING TABLES",
        sku: "TFMT- 406",
        image: conferenceTable8,
        details: "Oval Meeting Table  ",
        size: [
          "1800W x 900D x 750H",
          "2100W x 900D x 750H",
          "2400W x 120D x 750H",
        ],
        URL: "http://",
      },

      {
        name: "MEETING TABLES",
        sku: "TFMT- 407",
        image: conferenceTable9,
        details: "Round Discussion Table with Wooden Leg  ",
        size: [" D900 x 750H – 3 Person", "D1200 x 750H – 4 Person"],
        URL: "http://",
      },

      {
        name: "MEETING TABLES",
        sku: "TFMT- 408",
        image: conferenceTable10,
        details: "Round Discussion Table With ss Leg  ",
        size: [" D900 x 750H – 3 Person", "D1200 x 750H – 4 Person"],
        URL: "http://",
      },

      {
        name: "MEETING TABLES",
        sku: "TFMT- 409",
        image: conferenceTable11,
        details: "Round Discussion Table with Powder Coated MS Legs ",
        size: [" D900 x 750H – 3 Person", "D1200 x 750H – 4 Person"],
        URL: "http://",
      },

      {
        name: "OFFICE CHAIRS & SOFAS",
        sku: "TC-119V",
        image: conferenceTable12,
        details: "Chairs ",
        size: ["office charis "],
        URL: "http://",
      },
    ],

    workstationOpenDesk: [
      {
        name: "WORKSTATIONS (DESKING)",
        sku: "WS-5",
        image: openDesk1,
        details:
          "B2B Workstation With 18mm front Privacy Screen and side cabinet with 1 shutter and 3 drawer ",
        size: ["Workstation", " 1200x600x750/1050", "1500x600x750/1050"],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (DESKING)",
        sku: "WS-4",
        image: openDesk2,
        details:
          "B2B Workstation With Frosted Glass Privacy Screen and 3 Drawer Pedestal  ",
        size: ["Workstation", " 1200x600x750/1050", "1500x600x750/1050"],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (DESKING)",
        sku: "WS-3",
        image: openDesk3,
        details:
          "Cubical  Workstation With 30MM Front Privacy Screen With Soft Board",
        size: [
          "Workstation",
          " 1200/1200x600x750/1050",
          "1500/1500x600x750/1050",
        ],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (DESKING)",
        sku: "WS-2",
        image: openDesk4,
        details:
          "Cubical  Workstation With 30MM Front Privacy Screen With Soft Board",
        size: ["Workstation", " 1200/600x750/1050", "1500/600x750/1050"],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (DESKING)",
        sku: "WS-1",
        image: openDesk5,
        details:
          "B2B Workstation With Sandwich Glass Privacy Screen and 3 Drawer Pedestal ",
        size: ["Workstation", " 1200/600x750/1050", "1500/600x750/1050"],
        URL: "http://",
      },
    ],

    workstationCubicless: [
      {
        name: "WORKSTATIONS (CUBICLES)",
        sku: "WS-11",
        image: cubicless1,
        details:
          "120 Degree Workstation with 60MM Front & Side Partition (Single & Double) Raceway. ",
        size: [" 1200/1200x600x750/1200", "1500/1500x600x750/1200"],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (CUBICLES)",
        sku: "WS-10",
        image: cubicless2,
        details:
          "Cubical Workstation with 60MM Front & Side Partition (Single & Double) Raceway. ",
        size: ["Woekstation", "1200x600x750/1200", "1500x600x750/1200"],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (CUBICLES)",
        sku: "WS-9",
        image: cubicless3,
        details:
          "L Shape Workstation with 60MM Front Partition (Single & Double) Raceway.",
        size: [
          "Woekstation",
          "1200/1200x600x750/1200",
          "1500/1500x600x750/1200",
        ],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (CUBICLES)",
        sku: "WS-8",
        image: cubicless4,
        details:
          "Cubical Workstation with 60MM Front Partition (Single & Double) Raceway.",
        size: [
          "Woekstation",
          "1200/1200x600x750/1200",
          "1500/1500x600x750/1200",
        ],
        URL: "http://",
      },

      {
        name: "WORKSTATIONS (CUBICLES)",
        sku: "WS-7",
        image: cubicless5,
        details:
          "Cubical Workstation with 60MM Front & Side Partition (Single & Double) Raceway.",
        size: [
          "Woekstation",
          "1200/1200x600x750/1200",
          "1500/1500x600x750/1200",
        ],
        URL: "http://",
      },
    ],

    raceptionCounter: [
      {
        name: "RECEPTION COUNTERS",
        sku: "TFRC-306",
        image: reccou1,
        details: "Reception counter ",
        size: [
          "Reception counter ",
          "1200Wx600Dx750x1050H",
          "1400Wx750Dx750x1050H",
          "1600Wx750Dx750x1050H",
        ],
        URL: "http://",
      },

      {
        name: "RECEPTION COUNTERS",
        sku: "TFRC-304",
        image: reccou2,
        details: "Reception counter ",
        size: [
          "Reception counter ",
          "1200Wx600Dx1050H",
          "1400Wx750Dx1050H",
          "2100Wx900Dx1050H",
        ],
        URL: "http://",
      },

      {
        name: "RECEPTION COUNTERS",
        sku: "TFRC-303",
        image: reccou3,
        details: "Reception counter ",
        size: [
          "Reception counter ",
          "1500Wx600Dx1050H",
          "1800Wx750Dx1050H",
          "2100Wx900Dx1050H",
        ],
        URL: "http://",
      },

      {
        name: "RECEPTION COUNTERS",
        sku: "TFRC-302",
        image: reccou4,
        details: "Reception counter ",
        size: [
          "Reception counter ",
          "1500Wx600Dx1050H",
          "1800Wx750Dx1050H",
          "2100Wx900Dx1050H",
        ],
        URL: "http://",
      },

      {
        name: "RECEPTION COUNTERS",
        sku: "TFRC-301",
        image: reccou6,
        details: "Reception counter ",
        size: [
          "Reception counter ",
          " 1500W1x1500W2x750Dx1100H",
          " 1800W1x1800W2x750Dx1100H",
        ],
        URL: "http://",
      },
    ],

    storageCabinets: [
      {
        name: "STORAGE CABINETS",
        sku: "TFSD-117",
        image: cabinets1,
        details: "Storage With 1 shutter 3 drawer and open shelf.",
        size: ["900W x 450D x 750H"],
        URL: "http://",
      },

      {
        name: "STORAGE CABINETS",
        sku: "TFSD-116",
        image: cabinets2,
        details: "Storage With Swing Shutter",
        size: [
          "900W x 450D x 750H",
          "900W x 450D x 1200H",
          "900W x 450D x 1500H",
          "900W x 450D x 1980H",
        ],
        URL: "http://",
      },

      {
        name: "STORAGE CABINETS",
        sku: "TFSD-114",
        image: cabinets3,
        details: "Storage With Glass door",
        size: [
          "900W x 450D x 750H",
          "900W x 450D x 1200H",
          "900W x 450D x 1500H",
          "900W x 450D x 1980H",
        ],
        URL: "http://",
      },

      {
        name: "STORAGE CABINETS",
        sku: "TFSD-113",
        image: cabinets4,
        details: "Storage With Glass door",
        size: [
          "900W x 450D x 750H",
          "900W x 450D x 1200H",
          "900W x 450D x 1500H",
          "900W x 450D x 1980H",
        ],
        URL: "http://",
      },

      {
        name: "STORAGE CABINETS",
        sku: "TFSD-112",
        image: cabinets5,
        details: "Storage With Glass door",
        size: [
          "900W x 450D x 750H",
          "900W x 450D x 1200H",
          "900W x 450D x 1500H",
          "900W x 450D x 1980H",
        ],
        URL: "http://",
      },

      {
        name: "STORAGE CABINETS",
        sku: "TFFSD-107",
        image: cabinets6,
        details: "Mobile Pedestal with 3 Drawers ",
        size: ["400W x 450D x 685H"],
        URL: "http://",
      },
    ],
  },
  url: "http://",

  // metal storage
  metalStorage: {
    metalalmirah: [
      {
        name: "Office Almirah",
        sku: "TF-IF-9033",
        image: MetalAlmirah1,
        details: "Office Almirah",
        size: [],
        URL: "http://",
      },
      {
        name: "Both side openable Glass Door Almirah",
        sku: "TF-IF-9034",
        image: MetalAlmirah2,
        details: "Both side openable Glass Door Almirah",
        size: [],
        URL: "http://",
      },
      {
        name: "Almirah with sliding door",
        sku: "TF-IF-9049",
        image: MetalAlmirah3,
        details: "Almirah with sliding door",
        size: [],
        URL: "http://",
      },
    ],

    lokers: [
      {
        name: "Lockers",
        sku: "TF-IF-9036",
        image: locker1,
        details: "Lockers",
        size: [],
        URL: "http://",
      },
      {
        name: "Metal Storage",
        sku: "TF-IF-9037",
        image: locker2,
        details: "Lockers",
        size: [],
        URL: "http://",
      },
      {
        name: "Metal Storage",
        sku: "TF-IF-9038",
        image: locker3,
        details: "Lockers",
        size: [],
        URL: "http://",
      },
      {
        name: "Metal Storage",
        sku: "TF-IF-9039",
        image: locker4,
        details: "Metal Lockers",
        size: [],
        URL: "http://",
      },
    ],

    filingcabinets: [],

    bookshelves: [
      {
        name: "3 Door Book case",
        sku: "TF-IF-9042",
        image: sl1,
        details: "3 Door Book case",
        size: [],
        URL: "http://",
      },
      {
        name: "2 Door Book case",
        sku: "TF-IF-9043",
        image: sl2,
        details: "2 Door Book case",
        size: [],
        URL: "http://",
      },
      {
        name: "4 Drawer Book case",
        sku: "TF-IF-9044",
        image: sl3,
        details: "4 Drawer Book case",
        size: [],
        URL: "http://",
      },
      {
        name: "3 Drawer Book case",
        sku: "TF-IF-9045",
        image: sl4,
        details: "3 Drawer Book case",
        size: [],
        URL: "http://",
      },
      {
        name: "2 Drawer Book case",
        sku: "TF-IF-9046",
        image: sl5,
        details: "4 Drawer Book case",
        size: [],
        URL: "http://",
      },
    ],

    slottedAngleRacks: [],
  },

  // office chairs
  officeChairSofas: [
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BOSS-MB",
      image: chair1,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BOSS-HB",
      image: chair2,
      details: "Boss High Back Chair ",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-520-HB",
      image: chair3,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-101EH",
      image: chair4,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-102EH",
      image: chair5,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-104EH",
      image: chair6,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-106EH",
      image: chair7,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-101EM",
      image: chair8,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-102EM",
      image: chair9,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-104EH",
      image: chair10,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-106EH",
      image: chair11,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-101EH",
      image: chair12,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-102EH",
      image: chair13,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-104EM",
      image: chair14,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-H.B.-647",
      image: chair15,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-ERGO-MESH",
      image: chair16,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC- 651 -HB",
      image: chair17,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC- 651 -MB",
      image: chair18,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-817 HB",
      image: chair19,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC- SUPER H",
      image: chair20,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-105M",
      image: chair21,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-104M",
      image: chair22,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-103M",
      image: chair23,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-112H",
      image: chair24,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: " TC-105H",
      image: chair25,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-104H",
      image: chair26,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-112M",
      image: chair27,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-107M",
      image: chair28,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-108M",
      image: chair29,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-109M",
      image: chair30,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: " TC-114H",
      image: chair31,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-4009- MESH",
      image: chair32,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-4009-PU",
      image: chair33,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-135M",
      image: chair34,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-132M",
      image: chair35,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-115M",
      image: chair36,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: " TC-114M",
      image: chair37,
      details: "Chair",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-DIN019",
      image: chair38,
      details: "Chair",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-029SC",
      image: chair39,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-119V",
      image: chair40,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-117V",
      image: chair41,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-117WA",
      image: chair42,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-118V",
      image: chair43,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-019SCP",
      image: chair44,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BS14",
      image: chair45,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BS13",
      image: chair46,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BS12",
      image: chair47,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BS11",
      image: chair48,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-BS10",
      image: chair49,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-025SC",
      image: chair50,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-P01",
      image: chair51,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-P04",
      image: chair52,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-024SC",
      image: chair53,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-022SC",
      image: chair54,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-021SC",
      image: chair55,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-P13",
      image: chair56,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "SAHIRA",
      image: chair57,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: " SAHIRA 02",
      image: chair58,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-P18",
      image: chair59,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-P16",
      image: chair60,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-P11",
      image: chair61,
      details: "BOSS-MB",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "SLEEK",
      image: chair62,
      details: "SLEEK SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "HELIOS",
      image: chair63,
      details: "HELIOS SOFA",
      size: [],
      URL: "http://",
    },

    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "VOGUE",
      image: chair64,
      details: "VOGUE SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "ELITE",
      image: chair65,
      details: "ELITE SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "VAJRA",
      image: chair66,
      details: "VAJRA SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "REGAL",
      image: chair67,
      details: "REGAL SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TOFFEE",
      image: chair68,
      details: "TOFFEE SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "SASHWAT",
      image: chair69,
      details: "SASHWAT SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "MOKSHIT",
      image: chair70,
      details: "MOKSHIT SOFA",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-APB01",
      image: chair71,
      details: "TC-APB01 Visitors Bench ",
      size: [],
      URL: "http://",
    },
    {
      name: "OFFICE CHAIRS & SOFAS",
      sku: "TC-APB02",
      image: chair72,
      details: "TC-APB02 Visitors Bench ",
      size: [],
      URL: "http://",
    },
  ],

  // conpactors
  compactors: [
    {
      name: "Compactors",
      sku: "TF-IF-9049",
      image: compactor1,
      details: "Compactors",
      size: [""],
      URL: "http://",
    },
    {
      name: "Compactors",
      sku: "TF-IF-9050",
      image: compactor2,
      details: "Compactors",
      size: [""],
      URL: "http://",
    },
    {
      name: "Compactors",
      sku: "TF-IF-9051",
      image: compactor3,
      details: "Compactors",
      size: [""],
      URL: "http://",
    },
  ],

  // institutional-furniture
  institutionalFurniture: {
    smartDesksDualDesks: [
      {
        name: "SMART DESKS & DUAL DESKS",
        sku: "DAKSH Model No- DK01",
        image: institutional1,
        details: "Smart Desks for Classrooms",
        size: [""],
        URL: "http://",
      },
    ],

    computerLabAndLibraryDesk: [
      {
        name: "COMPUTER LAB AND LIBRARY DESKS",
        sku: "TF-IF-9015",
        image: computerlab1,
        details: "",
        size: [""],
        URL: "http://",
      },
      {
        name: "COMPUTER LAB AND LIBRARY DESKS",
        sku: "TF-IF-9012",
        image: computerlab2,
        details: "Computer Lab And Library Desks",
        size: [""],
        URL: "http://",
      },
      {
        name: "COMPUTER LAB AND LIBRARY DESKS",
        sku: "TF-IF-9011",
        image: computerlab3,
        details: "Computer Lab And Library Desks",
        size: [""],
        URL: "http://",
      },
      {
        name: "COMPUTER LAB AND LIBRARY DESKS",
        sku: "TF-IF-9012",
        image: computerlab1,
        details: "Computer Lab And Library Desks",
        size: [""],
        URL: "http://",
      },
    ],

    canteenAndCafeteriaFurniture: [
      {
        name: "CANTEEN AND CAFETERIA FURNITURE",
        sku: "TF-IF-9020",
        image: conteen1,
        details: "Canteen And Cafeteria Furniture",
        size: [],
        URL: "http://",
      },

      {
        name: "CANTEEN AND CAFETERIA FURNITURE",
        sku: "TF-IF-1918",
        image: conteen2,
        details: "Canteen And Cafeteria Furniture",
        size: [],
        URL: "http://",
      },
      {
        name: "CANTEEN AND CAFETERIA FURNITURE",
        sku: "TF-IF-9017",
        image: conteen3,
        details: "Canteen And Cafeteria Furniture",
        size: [],
        URL: "http://",
      },
      {
        name: "CANTEEN AND CAFETERIA FURNITURE",
        sku: "TF-IF-9016",
        image: conteen4,
        details: "Canteen And Cafeteria Furniture",
        size: [],
        URL: "http://",
      },
    ],

    labraryRacks: [
      {
        name: "LIBRARY RACKS",
        sku: "TF-RAC05",
        image: library1,
        details: "LIBRARY RACKS",
        size: ["juhjhj", "lorem-ipsum", "lorem-"],
        URL: "http://",
      },
      {
        name: "LIBRARY RACKS",
        sku: "TF-RAC04",
        image: library2,
        details: "LIBRARY RACKS",
        size: ["juhjhj", "lorem-ipsum", "lorem-"],
        URL: "http://",
      },
      {
        name: "LIBRARY RACKS",
        sku: "TF-RAC03",
        image: library3,
        details: "LIBRARY RACKS",
        size: ["juhjhj", "lorem-ipsum", "lorem-"],
        URL: "http://",
      },
      {
        name: "LIBRARY RACKS",
        sku: "TF-RAC02",
        image: library4,
        details: "LIBRARY RACKS",
        size: ["juhjhj", "lorem-ipsum", "lorem-"],
        URL: "http://",
      },
      {
        name: "LIBRARY RACKS",
        sku: "TF-RAC01",
        image: library5,
        details: "LIBRARY RACKS",
        size: ["juhjhj", "lorem-ipsum", "lorem-"],
        URL: "http://",
      },
    ],

    computerTable: [
      {
        name: "COMPUTER TABLES",
        sku: "TF-IF-9032",
        image: computerTable1,
        details: "COMPUTER TABLES",
        size: [""],
        URL: "http://",
      },

      {
        name: "COMPUTER TABLES",
        sku: " TF-IF-9031",
        image: computerTable2,
        details: "COMPUTER TABLES",
        size: [""],
        URL: "http://",
      },
      {
        name: "COMPUTER TABLES",
        sku: " TF-IF-9030",
        image: computerTable3,
        details: "COMPUTER TABLES",
        size: [""],
        URL: "http://",
      },

      {
        name: "COMPUTER TABLES",
        sku: " TF-IF-9029",
        image: computerTable4,
        details: "COMPUTER TABLES",
        size: [""],
        URL: "http://",
      },
      {
        name: "COMPUTER TABLES",
        sku: " TF-IF-9028",
        image: computerTable5,
        details: "COMPUTER TABLES",
        size: [""],
        URL: "http://",
      },
    ],

    podium: [
      {
        name: "PODIUM",
        sku: "TF-Podium - 003",
        image: podium1,
        details: "PODIUM",
        size: [],
        URL: "http://",
      },
      {
        name: "PODIUM",
        sku: "TF-Podium - 002",
        image: podium2,
        details: "PODIUM",
        size: [],
        URL: "http://",
      },
      {
        name: "PODIUM",
        sku: "TF-Podium - 001",
        image: podium3,
        details: "PODIUM",
        size: [],
        URL: "http://",
      },
    ],
  },

  // hotel and hostel furniture
  HotelAndHostelFurniture: [
    {
      name: "HOSTEL BEDS",
      sku: "TF-IF-9027",
      image: hotelhostel1,
      details: "HOSTEL BEDS",
      size: [""],
      URL: "http://",
    },

    {
      name: "HOSTEL BEDS",
      sku: "TF-IF-9026",
      image: hotelhostel2,
      details: "HOSTEL BEDS",
      size: [""],
      URL: "http://",
    },
    {
      name: "HOSTEL BEDS",
      sku: "TF-IF-9025",
      image: hotelhostel3,
      details: "HOSTEL BEDS",
      size: [""],
      URL: "http://",
    },
    {
      name: "HOSTEL BEDS",
      sku: "TF-IF-9024",
      image: hotelhostel4,
      details: "HOSTEL BEDS",
      size: [""],
      URL: "http://",
    },
    {
      name: "HOSTEL BEDS",
      sku: "TF-IF-9023",
      image: hotelhostel5,
      details: "HOSTEL BEDS",
      size: [""],
      URL: "http://",
    },
    {
      name: "HOSTEL BEDS",
      sku: "TF-IF-9022",
      image: hotelhostel6,
      details: "HOSTEL BEDS",
      size: [""],
      URL: "http://",
    },
    {
      name: "Hostel Furniture",
      sku: "TF-IF-9021",
      image: hotelhostel7,
      details: "Hostel Furniture",
      size: [""],
      URL: "http://",
    },
  ],

  // Laboratory furniture
  Laboratoryfurniture: {
    ScienceLab: [
      {
        name: "SCIENCE LABS",
        sku: "TF-LAB06",
        image: slab1,
        details: "SCIENCE LABS",
        size: [""],
        URL: "http://",
      },
      {
        name: "SCIENCE LABS",
        sku: "TF-LAB05",
        image: slab2,
        details: "SCIENCE LABS",
        size: [""],
        URL: "http://",
      },
      {
        name: "SCIENCE LABS",
        sku: "TF-LAB04",
        image: slab3,
        details: "SCIENCE LABS",
        size: [""],
        URL: "http://",
      },
      {
        name: "SCIENCE LABS",
        sku: "TF-LAB03",
        image: slab4,
        details: "SCIENCE LABS",
        size: [""],
        URL: "http://",
      },
      {
        name: "SCIENCE LABS",
        sku: "TF-LAB02",
        image: slab5,
        details: "SCIENCE LABS",
        size: [""],
        URL: "http://",
      },
      {
        name: "SCIENCE LABS",
        sku: "TF-LAB01",
        image: slab6,
        details: "SCIENCE LABS",
        size: [""],
        URL: "http://",
      },
    ],
  },

  homeFurniture: [
    {
      name: "WFH Office",
      sku: "",
      image: homeFurniture1,
      details: "WFH Office",
      size: [""],
      URL: "http://",
    },
    {
      name: "Living Room",
      sku: "",
      image: homeFurniture2,
      details: "Living Room",
      size: [""],
      URL: "http://",
    },
    {
      name: "kITCHEN",
      sku: "",
      image: homeFurniture3,
      details: "kITCHEN",
      size: [""],
      URL: "http://",
    },
  ],
};

export default Categories;
