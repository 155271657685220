import founder1 from "../Assets/Images/About/mahesh.webp";
import founder2 from "../Assets/Images/About/vardaji.webp";

export const founderList = [
  {
    name: "Varadraj KP",
    designation: "Cofounder",
    profileImage: founder2,
    about:
   ` From a humble start in 2006 to the day when I am penning down my opinion on the business, the one thing that I have firmly come to believe in is the fact that there's never a shortcut to success. One's hardwork and sincerity may take time in paying off but it does, eventually. Our perseverance and resilience have been the key to the milestones we have achieved in all these years. The acceptance that we receive from our valued customers keeps us going and we strive to continue walking on the path of DADS-Determination, Acceptance, Dedication & Sincerity. We are "Determined" to reach our goal of making Tempus a global brand in Modular furniture, for which we work in close association with the new generation to ensure "Acceptance" by the volatile needs of the market without ever compromising with our core principle of "Dedication & Sincerity".`  },
  {
    name: "Mahesh Jain",
    designation: "Cofounder",
    profileImage: founder1,
    about:
    "The Indian Furniture Industry has come a long way in the last decade. The futuristic designs coupled with technology driven manufacturing were once considered far from possible for Indian manufacturers. Quite astonishingly in the current times our indigenous manufacturing facilities are giving a run for their money to the leading international brands from the furniture industry. Today, our industry is way more organized, focused on research & development, technology driven and working towards delivering absolute value for money. We, as a small member of this huge Indian Furniture Family are determined to do our bit and contribute towards global recognition of our products. I reckon, Indian designs and make will have a huge demand in the overseas market in times to come and the furniture industry shall surely prove to be an absolute revenue generating industry for our nation." 
  },
];

export const perksList = [
  {
    perk: "Guaranteed customer satisfaction every time.",
  },
  {
    perk: "Cleaning services that give you great value for your money.",
  },
  {
    perk: "A team of talented and skilled cleaners ready to tackle any job.",
  },
  {
    perk: "Eco-friendly practices that reduce our environmental impact.",
  },
];
