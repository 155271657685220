import React from "react";
import "./AboutUs.scss";
import { founderList, perksList } from "../../constants/constants";
import company from "../../Assets/Images/company.jpeg";
import company1 from "../../Assets/about/inside_factory_pic_3-transformed.jpg";

const AboutUs = () => {
  return (
    <section className="about-us-container">
      <div className="heading-section">
        <h5 className="heading">Our Directors</h5>
      </div>

      <div className="founders-container">
        {founderList.map((item, index) => (
          <div key={index} className="founder-container">
            <div className="left-container">
              <div className="founder-image-container">
                <img
                  src={item.profileImage}
                  alt={`${item.name} image`}
                  className="founder-image"
                />
              </div>
            </div>

            <div className="right-container">
              <div className="primary-details">
                <h5 className="name">{item.name}</h5>
                <p className="designation">{item.designation}</p>
              </div>

              <p className="about">{item.about}</p>
            </div>
          </div>
        ))}
      </div>

      <h4 className="subheading text-center mt-5">
        Choose us and you will receive
      </h4>
      <div className="about-company-container mt-4">
        <div className="company-image-container">
          <div className="image-container">
            <img src={company1} alt="company image" className="company-image" />
          </div>
        </div>

        <div className="company-details">
          <div className="company-primary-details">
            {/* <ul>
              {perksList.map((item, index) => (
                <li>
                  <p>{item.perk}</p>
                </li>
              ))}
            </ul> */}
          </div>

          {/* <p className="para">
            We are a fast-growing and promising office and institutional
            furniture manufacturing company in India. With experienced
            individuals from the furniture industry and state-of-the-art
            infrastructure that is regularly updated to meet the ever-changing
            market demands, we pride ourselves on being progressive and
            forward-thinking. Our advanced manufacturing facilities in Bangalore
            and Indore, equipped with modern machinery, allow us to produce both
            panel-based and metal furniture. Our unique selling point lies in
            our in-house capabilities, enabling us to offer tailored designs and
            customizations without limitations.
          </p> */}

          <p className="par">
            <b>Uncompromising Quality:</b> Furniture built to last, with premium
            materials and craftsmanship, undergoing rigorous quality checks.
          </p>

          <p>
            <b>Ergonomic Excellence:</b>
            Designed for comfort and productivity, promoting healthy posture and
            reducing strain.
          </p>
          <p>
            <b>Custom Solutions:</b>
            Tailored furniture to fit unique workspace needs, with options for
            dimensions, finishes, and configurations.
          </p>
          <p>
            <b>On-Time Delivery:</b>
            Reliable production and logistics ensure timely delivery for
            uninterrupted business.
          </p>
          <p>
            <b>Sustainable Practices:</b>
            Environmentally responsible manufacturing with sustainably sourced
            materials and eco-friendly processes.
          </p>
          <p>
            <b>Exceptional Support:</b>
            Dedicated support team for post-sale assistance, maintenance, and
            guidance.
          </p>
          <p>
            <b>Future-Ready Designs:</b>
            Durable, stylish furniture that combines classic and modern
            aesthetics for lasting appeal.
          </p>
        </div>
      </div>
      {/* <p>
        <b>Exceptional Support:</b>
        Dedicated support team for post-sale assistance, maintenance, and
        guidance.
      </p>
      <p>
        <b>Future-Ready Designs:</b>
        Durable, stylish furniture that combines classic and modern aesthetics
        for lasting appeal.
      </p> */}
    </section>
  );
};

export default AboutUs;
