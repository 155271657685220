import styles from "../about/about.module.css";
import randomImg from "../../Assets/Images/Final_img/10.jpg";
import teamImg from "../../Assets/Images/Final_img/10.jpg";
import aboutCoverImg from "../../Assets/Images/Final_img/10.jpg";
import aboutImg from "../../Assets/Images/Services/img4.webp";


const Home_clean = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <div
          className={styles.heading}
          style={{ backgroundImage: `url(${aboutCoverImg})` }}
        >
          {" "}
          Delivery & Installation{" "}
        </div> */}

        {/* <div className={styles.ourStoryWrapper}>
          <span className={styles.ourStoryHead}>
            {" "}
            Home cleaning is the process of tidying, organizing, and sanitizing
            living spaces to maintain a clean and healthy environment for
            tenants and family members. It involves various tasks aimed at
            removing dirt, dust, grime, and germs from surfaces, floors,
            furniture, and other areas of the home.{" "}
          </span>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              Importance of home cleaning :-
            </span>

            <span className={styles.ourStoryDesc}>
              • Regular home cleaning helps prevent the build up of allergens,
              bacteria, and other harmful pathogens that can contribute to
              respiratory problems, allergies, and illnesses. A clean home
              promotes better health and well-being for everyone living in it.
              <br />
              <br />
              • A tidy and organized home provides a more comfortable and
              relaxing environment for occupants to unwind and recharge after a
              long day. Clutter-free spaces can also help reduce stress and
              promote mental clarity and focus. tidy and organized home provides
              a more comfortable and relaxing environment for occupants to
              unwind and recharge after a long day. Keeping your home clean and
              well-maintained instills a sense of pride and satisfaction home
              ownership.
              <br />
              <br />
              • A clean home reflects attention to detail, care for the living
              environment, and pride in one’s surroundings. We at Best 1
              Cleaners take pride to clean your homes with green products to
              keep you and your family member safe and healthy. You can choose
              the cleaner you want and if you book us for regular cleaning then
              cleaner who come to you sat first time will stay for the end, and
              we will not lock you into the regular contract or weekly .
              <br />
              <br />
              • In this busy era , no one has time to spend it on cleaning but
              cleaning is very essential as without cleanliness we will get sick
              . We have family to spend our valuable time with them. So, it’s
              difficult to find time and energy for something extra work.
              <br />
              <br />
              <b>
                With the best one cleaners , all of your cleaning needs will be
                not only completed but also satisfied with green environment
                around your homes.{" "}
              </b>
            </span>
          </div>

        </div> */}

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            {/* <span className={styles.aboutHead}>
              {" "}
              Capital Climb - 2X: Secure Land Investments with Guaranteed Growth
            </span> */}
            <h4>Delivery & Installation</h4>
            <span className={styles.aboutDesc}>
              Our delivery and installation services ensure a seamless
              experience for customers. Timely and efficient, our team handles
              the transportation of your goods with care, ensuring they arrive
              in pristine condition. Our skilled professionals then expertly
              install and assemble the items, guaranteeing a hassle-free process
              and leaving you with a fully functional and aesthetically pleasing
              space.
              <br />
              <br />
            </span>
          </div>

          {/* <img src={randomImg} className={styles.aboutImg} alt="" /> */}
          <div className={styles.video}>
            {/* <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video> */}
            <img src={aboutImg} alt="" />
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            {/* <span className={styles.ourStoryHead}> With Our Teams </span> */}

            {/* <span className={styles.ourStoryDesc}>
              With Capital Climb - 2X, retail investors have the opportunity to
              invest securely. In this model, investments are used exclusively
              for acquiring premium land parcels, which are carefully selected
              for high growth potential. The team ensures that the land has
              clear titles and legal approvals, with bank support in place for
              each project. <br />
              Investors enjoy an unconditional 2x return on investment within 5
              years, secured against tangible land assets. The proven model of
              successful land trading and joint development minimizes risk,
              while investor participation helps expand the network across India
              and beyond, unlocking wider sales opportunities. <br />
              This model is suitable for investors looking to diversify their
              portfolios or invest in a stable, high-growth real estate market,
              providing a secure path to double investments in just 5 years
              without any operational engagement. It capitalizes on Goa’s
              booming real estate market with confidence, ensuring all
              investments are safe and yield guaranteed returns.
              <br />
              Business Opportunities on Floor Land ,Villa & Apartments
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home_clean;
