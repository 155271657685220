import styles from "./Services.module.css";
import servicesData from "../../Assets/Data/services";
import { Link, useNavigate } from "react-router-dom";

function ServiceCard({ serviceName, imgURL, Icon, link }) {
  const navigate = useNavigate();

  return (
    <div className={styles.servicardMain}>
      <Link
        to={link}
        className={styles.cardWrapper}
        style={{ backgroundImage: `url(${imgURL})` }}
        onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicked
      >
        <Icon className={styles.serviceLogo} />
        <span className={styles.serviceName}>{serviceName}</span>
      </Link>

      <button
        onClick={() => {
          navigate(link);
          window.scrollTo(0, 0);
        }}
      >
        Learn More...
      </button>
    </div>
  );
}

function Services() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {servicesData.map((service) => {
          return (
            <ServiceCard
              link={service.link}
              serviceName={service.name}
              imgURL={service.imgURL}
              Icon={service.logo}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Services;
