import styles from "../about/about.module.css";
import randomImg from "../../Assets/Images/Final_img/6.jpg";
import teamImg from "../../Assets/Images/Final_img/1.jpg";
import aboutCoverImg from "../../Assets/Images/Services/3913.jpg";
import aboutImg from "../../Assets/Images/Services/img3.webp";

const Carpet = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <div
          className={styles.heading}
          style={{ backgroundImage: `url(${aboutCoverImg})` }}
        >
          {" "}
          Tailor Made Furniture{" "}
        </div> */}

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutHead}>
              {" "}
              {/* OUR BEST 1 CLEANERS CLEANING PROCESS :- */}
            </span>

            <span className={styles.aboutDesc}>
              <h4>Tailor Made Furniture</h4>
              Tailor-made furniture is crafted with precision to suit individual
              preferences and spaces. This bespoke approach allows clients to
              actively participate in the design process, choosing materials,
              styles, and dimensions. The result is unique, high-quality
              furniture that perfectly aligns with personal taste and
              complements the specific aesthetics of the intended environment.
            </span>
          </div>

          {/* <img src={randomImg} className={styles.aboutImg} alt="" /> */}
          <div className={styles.video}>
            {/* <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video> */}
            <img src={aboutImg} alt="" />
          </div>
        </div>

        <div
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "1rem",
            // backgroundColor: "#ace1af",
          }}
        >
          {/* <span className={styles.ourStoryHead} style={{ color: "black" }}>
            What our experts can do : -
          </span>
          <ul>
            <li> Spot and stain removal</li>
            <li>Shampooing + deodorizing</li>
            <li>Low moisture cleaning</li>
            <li>Pet stain and Odor removal</li>
            <li>Carpet protection ( scotchguard )</li>
            <li>Deep cleaning of high traffic Areas</li>
            <li>Hot water Extraction ( steam cleaning ) </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default Carpet;
