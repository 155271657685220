import styles from "../../Components/Services/Services.module.css";
import FAQ from "../../Components/FAQ/FAQ";

import { Services } from "../../Components";
import img1 from "../../Assets/Images/Services/img1.webp";
import img2 from "../../Assets/Images/Services/img2.webp";
import img3 from "../../Assets/Images/Services/img3.webp";
import img4 from "../../Assets/Images/Services/img4.webp";

const data = [
  {
    title: "What material do you use in making furniture?",
    description:
      "We deal in all sorts of engineered wood namely Ply, MDF, HDHMR and PLPB. Besides, we work in both sheet metal and MS pipes too. At certain places we use SS, Glass and Fabric too. Sofa structures are made both in Metal and Wood.",
  },
  {
    title: "How is factory made furniture better than carpentry work?",
    description:
      "Furniture at our factory is made with advance technology driven German & Japanese machinery which ensures precision and finesse with all the edges being subtle and smooth,  We have Hot Press Machine for pasting laminate which works with high pressure and temperature,  Hassel free process for the customer as factory made furniture involves no mess at the site, The ready to install material is delivered in knock down state and the only job done at site is of Installation of parts.",
  },
  {
    title: "Where in India do you supply your furniture?",
    description:
      "We have dual manufacturing facilities, 1 at Bangalore and other at Indore and we do pan India delivery.",
  },
  {
    title: "Do you customize designs as per client’s need?",
    description:
      "Yes, absolutely! Infact bespoke furniture is our USP and we can customize designs as per our client’s need.",
  },
  {
    title: "What is the warranty period offered on your products?",
    description:
      "A Warranty period of 1 year from the date of delivery on manufacturing defects is offered by us.",
  },
  {
    title: "What is the turnaround time of an order?",
    description:
      "The exact turnaround time depends on the designs, quantum of work and the availability of material in stock however, in general we take",
  },
];

function ServiceCard({ serviceName, imgURL, Icon }) {
  return (
    <div
      className={styles.cardWrapper}
      style={{ backgroundImage: `url(${imgURL})` }}
    >
      <Icon className={styles.serviceLogo} />
      <span className={styles.serviceName}> {serviceName} </span>
    </div>
  );
}
function Servicess() {
  return (
    <>
      <div className={styles.contact_header}> Our Services </div>

      <div className={styles.servicecon}>
        <div className={styles.service1}>
          <div className={styles.leftImg}>
            <img src={img1} alt="" />
          </div>
          <div className={styles.rightCon}>
            <h4>Design Consultation</h4>
            <p>
              Design consultation is a collaborative process where experts
              analyze client needs, preferences, and space requirements. Through
              in-depth discussions, designers offer tailored solutions,
              integrating aesthetics and functionality. This personalized
              approach ensures the creation of spaces that not only reflect the
              client's vision but also optimize the use of available resources.
            </p>
          </div>
        </div>

        <div className={styles.service2}>
          <div className={styles.rightCon}>
            <h4>Tailor Made Furniture</h4>
            <p>
              Tailor-made furniture is crafted with precision to suit individual
              preferences and spaces. This bespoke approach allows clients to
              actively participate in the design process, choosing materials,
              styles, and dimensions. The result is unique, high-quality
              furniture that perfectly aligns with personal taste and
              complements the specific aesthetics of the intended environment.
            </p>
          </div>

          <div className={styles.leftImg}>
            <img src={img3} alt="" />
          </div>
        </div>

        <div className={styles.service1}>
          <div className={styles.leftImg}>
            <img src={img4} alt="" />
          </div>
          <div className={styles.rightCon}>
            <h4>Delivery & Installation</h4>
            <p>
              Our delivery and installation services ensure a seamless
              experience for customers. Timely and efficient, our team handles
              the transportation of your goods with care, ensuring they arrive
              in pristine condition. Our skilled professionals then expertly
              install and assemble the items, guaranteeing a hassle-free process
              and leaving you with a fully functional and aesthetically pleasing
              space.
            </p>
          </div>
        </div>

        {/* <div className={styles.service1}>
          <div className={styles.rightCon}>
            <h4>Warranty & Maintenance</h4>
            <p>
              We Offer one year warranty on manufacturing defects on furniture
              supplied by us, from the date of delivery with some exclusions as
              under:- <br />
              • Defects/Damage occurring due to dismantling and reassembling of
              furniture by the client on his own. <br /> • Defects/Damage
              occurring due to furniture being directly exposed to extreme
              climate, sunlight, fire and moisture. <br /> • Defects/Damage
              occurring due to inappropriate usage in terms of weight being put
              on it, dragging the furniture from one place to another etc.{" "}
              <br /> • Defects occurring due to physical damage
              knowingly/unknowingly done to the furniture at the customer's end.
            </p>
          </div>

          <div className={styles.leftImg}>
            <img src={img2} alt="" />
          </div>
        </div> */}
      </div>

      <div className={styles.faqContainer}>
        <FAQ data={data} />
      </div>
    </>
  );
}

export default Servicess;
