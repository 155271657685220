import { GiChickenOven } from "react-icons/gi";
import { GiBroom } from "react-icons/gi";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaTools } from "react-icons/fa";
import { MdMapsHomeWork } from "react-icons/md";

// Background Images
import img1 from "../../Assets/Images/Services/img1.webp";
import img2 from "../../Assets/Images/Services/img2.webp";
import img3 from "../../Assets/Images/Services/img3.webp";
import img4 from "../../Assets/Images/Services/img4.webp";

import { MdOutlineAutoGraph } from "react-icons/md";

const servicesData = [
  {
    name: "Design Consultation",
    imgURL: img1,
    logo: MdOutlineAutoGraph,
    link: "/preset",
  },
  // {
  //   name: "Warranty & Maintenance",
  //   imgURL: img2,
  //   logo: GiBroom,
  //   link: "/jointventure",
  // },
  {
    name: "Tailor Made Furniture",
    imgURL: img3,
    logo: MdMapsHomeWork,
    link: "/2xcapital",
  },
  {
    name: "Delivery & Installation",
    imgURL: img4,
    logo: HiOfficeBuilding,
    link: "/Rentals",
  },
];

export default servicesData;
