import styles from "./ContactUs.module.css";
import { FaInstagram, FaFacebook, FaPhone } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useEffect } from "react";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";


function ContactUs() {
  const navigate = useNavigate();
  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "best1_cleaners",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    fullName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  //done
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setFormData({
  //     email: "",
  //     phoneNumber: "",
  //     message: "",
  //     fullName: "",
  //   });

  //   // Replace these with your actual email service, template ID, and user ID
  //   const serviceId = process.env.REACT_APP_SERVICE_ID;
  //   console.log("serviceId: ", serviceId);
  //   const templateId = process.env.REACT_APP_TEMPLATE_ID;
  //   console.log("templateId: ", templateId);

  //   try {
  //     await emailjs.send(serviceId, templateId, formData, {
  //       publicKey: process.env.REACT_APP_PUBLIC_KEY,
  //     });

  //     // Clear form data after successful submission
  //     setFormData({
  //       email: "",
  //       phoneNumber: "",
  //       message: "",
  //       fullName: "",
  //     });

  //     toast.success("Thank you! Your message has been sent successfully.", {
  //       position: "bottom-right",
  //       autoClose: 4000,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "light",
  //     });
  //   } catch (error) {
  //     toast.success("Thank you! Your message has been sent successfully.", {
  //       position: "bottom-right",
  //       autoClose: 4000,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "light",
  //     });
  //     console.error("Error sending email:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extract the form data to ensure it remains intact for email sending
    const dataToSend = { ...formData };

    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        dataToSend,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      );

      // Clear form data after successful submission
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: "",
        message: "",
        phoneNumber: "",
        fullName: "",
      }));

      toast.success("Thank you! Your message has been sent successfully.", {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      toast.error("Failed to send your message. Please try again later.", {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <div className={styles.contact_us}>
      <h2 className={`text-center ${styles.heading}`}>Contact Us</h2>

      <div className={styles.contactFormAndAddress}>
        <div className={styles.contact_locations}>
          {/* <h4 className={styles.subheading}>Get In Touch</h4> */}

          <div className={styles.contactContainer}>
            {/* <div>
              <h6>Mobile Number</h6>
              <p>+91 9302055555</p>
            </div> */}

            <div>
              <h5>Email</h5>
              <p className="mt-2">info@tempusfurniture.com</p>
              <p className={styles.span}>Co-founder</p>
              <p>
                {" "}
                <span className={styles.span}>Mahesh Jain -</span>{" "}
                mahesh@tempusfurniture.com
              </p>
              <p>
                {" "}
                <span className={styles.span}>Varadraj KP -</span>{" "}
                products@vdecoindia.com
              </p>
            </div>
          </div>
          <div className={styles.whatsAppNumber}>
            {/* <h5>WhatsApp Number</h5> */}
            <a
              className={styles.linkcolor}
              href="https://wa.me/+919009988770?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
              target="_blank"
            >
              <FaWhatsapp size={19} />

              <p>+91 9009988770</p>
            </a>
          </div>

          <div className={styles.socialLinks}>
            <h5>Social Media </h5>

            <div style={{ marginTop: "7px" }}>
              <p>
                {" "}
                <a
                  className="text-danger"
                  href="https://www.instagram.com/tempus_furniture/"
                  target="_black"
                >
                  <FaInstagram size={30} />
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://www.facebook.com/TempusFurnitureSolutions/"
                  target="_black"
                >
                  <FaFacebook size={30} />
                </a>{" "}
              </p>

              <p>
                {" "}
                <a
                  className="text-danger"
                  href="https://www.youtube.com/@tempusfurniture7408"
                  target="_black"
                >
                  <FaYoutube size={30} />
                </a>{" "}
              </p>
              <p>
                {" "}
                <a
                  className="text-primary"
                  href="https://www.linkedin.com/company/tempus-furniture-solutions-llp/"
                  target="_black"
                >
                  <FaLinkedin size={30} />
                </a>{" "}
              </p>
            </div>
          </div>

          <div className={styles.locationContainer}>
            <h5>Factory location</h5>
            <p className="mt-3">
              {" "}
              <span className={styles.span}>INDORE</span> {""} Sanwer Road,
              Industrial Area, Indore-452015 (M.P.)
            </p>
            <p className="mt-2">
              {" "}
              <span className={styles.span}>BANGALORE</span> Doddanna Indu. Near
              Peenya 2nd stage, Bangalore-560091.
            </p>
            <p className="mt-1">
              {" "}
              <span className={styles.span}>BANGALORE</span> Peenya Industrial
              Area, Bangalore-560058.
            </p>
          </div>
        </div>

        <form className={styles.contact_form} onSubmit={handleSubmit}>
          <h4 className={`text-center ${styles.subheading}`}>Send A Message</h4>

          <div className={styles.formField}>
            <div className={styles.label}>Full Name</div>
            <input
              className={styles.formInput}
              type="text"
              id="fullName"
              placeholder="Enter Name"
              required
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}>Email</div>
            <input
              className={styles.formInput}
              type="email"
              onChange={handleInputChange}
              placeholder="Enter Email"
              value={formData.email}
              id="email"
              required
              name="email"
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}>Phone</div>
            <input
              className={styles.formInput}
              type="tel"
              required
              value={formData.phoneNumber}
              id="phone"
              placeholder="Enter PhoneNumber"
              onChange={handleInputChange}
              name="phoneNumber"
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}> Message </div>
            <textarea
              className={styles.formInput}
              id="message"
              name="message"
              placeholder="Enter Message"
              value={formData.message}
              onChange={handleInputChange}
              rows="4"
            />
          </div>

          <button className={styles.submitBtn} type="submit">
            Submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ContactUs;
