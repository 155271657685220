import React from "react";
import "./ClientCheck.scss";

import logo1 from "../../Assets/Client/1.jpeg";
import logo2 from "../../Assets/Client/2.jpeg";
import logo3 from "../../Assets/Client/3.png";
import logo4 from "../../Assets/Client/4.png";
import logo5 from "../../Assets/Client/5.jpeg";
import logo6 from "../../Assets/Client/6.jpeg";
import logo7 from "../../Assets/Client/7.png";
import logo8 from "../../Assets/Client/8.jpeg";
import logo9 from "../../Assets/Client/9.jpeg";
import logo11 from "../../Assets/Client/10.png";
import logo12 from "../../Assets/Client/11.png";
import logo13 from "../../Assets/Client/12.jpeg";
import logo14 from "../../Assets/Client/13.jpeg";
import logo15 from "../../Assets/Client/14.jpeg";
import logo16 from "../../Assets/Client/15.png";
import logo17 from "../../Assets/Client/16.jpeg";
import logo18 from "../../Assets/Client/17.png";
import logo19 from "../../Assets/Client/18.jpeg";
import logo10 from "../../Assets/Client/20.jpeg";
import logo20 from "../../Assets/Client/21.png";
import logo21 from "../../Assets/Client/22.png";
import logo22 from "../../Assets/Client/23.jpeg";
import logo23 from "../../Assets/Client/24.jpeg";
import logo24 from "../../Assets/Client/25.png";
import logo25 from "../../Assets/Client/26.png";
import logo26 from "../../Assets/Client/27.png";
import logo27 from "../../Assets/Client/28.jpeg";
import logo28 from "../../Assets/Client/29.png";
import logo29 from "../../Assets/Client/30.jpeg";
import logo30 from "../../Assets/Client/31.jpeg";
import logo31 from "../../Assets/Client/32.jpeg";
import logo32 from "../../Assets/Client/33.jpeg";
import logo33 from "../../Assets/Client/34.jpeg";
import logo34 from "../../Assets/Client/35.png";
import logo35 from "../../Assets/Client/36.jpeg";
import logo36 from "../../Assets/Client/37.png";
import logo37 from "../../Assets/Client/38.png";
import logo38 from "../../Assets/Client/39.jpeg";
import logo39 from "../../Assets/Client/40.jpeg";
import logo40 from "../../Assets/Client/41.png";
import logo41 from "../../Assets/Client/42.png";
import logo42 from "../../Assets/Client/43.jpeg";
import logo43 from "../../Assets/Client/44.png";
import logo44 from "../../Assets/Client/45.jpeg";
import logo45 from "../../Assets/Client/46.png";
import logo46 from "../../Assets/Client/47.png";
import logo47 from "../../Assets/Client/48.png";
import logo48 from "../../Assets/Client/49.png";
import logo49 from "../../Assets/Client/50.png";
import logo50 from "../../Assets/Client/51.jpeg";
import logo51 from "../../Assets/Client/52.jpeg";
import logo52 from "../../Assets/Client/53.jpeg";
import logo53 from "../../Assets/Client/54.jpeg";
import logo54 from "../../Assets/Client/55.png";
import logo55 from "../../Assets/Client/56.png";
import logo56 from "../../Assets/Client/57.png";
import logo57 from "../../Assets/Client/58.png";

const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo23,
  logo24,
  logo25,
  logo26,
  logo27,
  logo28,
  logo29,
  logo30,
  logo31,
  logo32,
  logo33,
  logo34,
  logo35,
  logo36,
  logo37,
  logo38,
  logo39,
  logo40,
  logo41,
  logo42,
  logo43,
  logo44,
  logo45,
  logo46,
  logo47,
  logo48,
  logo49,
  logo50,
  logo51,
  logo52,
  logo53,
  logo54,
  logo55,
  logo56,
  logo57,
];

function ClientCheck() {
  return (
    <div className="FirstDivOFClient">
      <h1 className="allClientTitle">Our Clientele</h1>
      <div className="listOfAllClient">
        {logos.map((logo, index) => (
          <div className="logoDiv" key={index}>
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientCheck;
