import React from "react";
import { useState } from "react";
import "./Gallery.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import img1 from "../../Assets/gallery/11_4b4f02bd-38c0-4283-ad48-3632df63623f (1).gif";
import img2 from "../../Assets/gallery/10 (1).gif";
import img3 from "../../Assets/gallery/11.gif";
import img4 from "../../Assets/gallery/12 (1).gif";
import img5 from "../../Assets/gallery/13 (1).gif";
import img6 from "../../Assets/gallery/13_a4cd6293-439e-479a-b916-b67a63a903b2 (1).gif";
import img7 from "../../Assets/gallery/14 (2).gif";
import img8 from "../../Assets/gallery/14 (3).gif";
import img9 from "../../Assets/gallery/1_0acc0bc3-285b-430f-abb4-d4882f46d5f7 (1).gif";
import img10 from "../../Assets/gallery/1_24f8825d-f59c-49d7-b871-de109a3f8f4a (2).gif";
import img11 from "../../Assets/gallery/1_426e26bd-6183-4bff-9d03-b7844be04fbf.gif";
import img12 from "../../Assets/gallery/1_459ebbe5-2433-4d29-89f4-1b2634cb83f8.gif";
import img13 from "../../Assets/gallery/1_587387b4-b1f0-465d-bed9-14950ffbbc1e.gif";
import img14 from "../../Assets/gallery/1_5c7585c8-7949-417d-a01f-287249c37fd0 (1).gif";
import img15 from "../../Assets/gallery/1_60332efe-8397-4622-b4c4-d94688091cd3.gif";
import img16 from "../../Assets/gallery/1_ace1b17a-4398-4d5d-94ac-f30ae5ec71f0 (1).gif";
import img17 from "../../Assets/gallery/1_c306cc1d-ad4a-49ad-acb7-3cfccc251414.gif";
import img18 from "../../Assets/gallery/1_e43932dd-3a2e-457e-a6eb-a28fbb3c6bed.gif";
import img19 from "../../Assets/gallery/1_ebedfb8b-5fea-40c7-98ca-ec5f09ed2602 (1).gif";
import img20 from "../../Assets/gallery/2_1a4a93b4-f1b0-4d1c-8a54-6ffe5487f7b2.gif";
import img21 from "../../Assets/gallery/2_25149ea3-c975-470d-b116-1e356f5e2ee9.gif";
import img22 from "../../Assets/gallery/2_3d777a33-fb69-49e0-8d90-52f21adf4a90.gif";
import img23 from "../../Assets/gallery/3_ca1171eb-22e3-417d-9275-48d3e51a987c.gif";
import img24 from "../../Assets/gallery/3_cbf67069-fb41-4ca6-9252-dde4c54fd92b.gif";
import img25 from "../../Assets/gallery/4_39abff41-23a8-48fe-9b81-9494ff62ca3a (1).gif";
import img26 from "../../Assets/gallery/4_72193084-7c43-42d1-a88f-e41d4b7d9aa6 (1).gif";
import img27 from "../../Assets/gallery/4_9310f9d0-0262-44e6-a7c1-4cbc33b7fdcb.gif";
import img28 from "../../Assets/gallery/4_9310f9d0-0262-44e6-a7c1-4cbc33b7fdcb.gif";
import img29 from "../../Assets/gallery/9_91daf6a4-dd19-4368-ba01-9190a2a4a93b.gif";
import img30 from "../../Assets/gallery/9.gif";
import img31 from "../../Assets/gallery/8_22e275da-fb72-4ee5-9ac1-29bda747391c (1).gif";
import img32 from "../../Assets/gallery/8 (1).gif";
import img33 from "../../Assets/gallery/10 (1).gif";
import img34 from "../../Assets/gallery/7_1fcf1f09-0b85-45e2-b469-7bb97c5c7821.gif";
import img35 from "../../Assets/gallery/7_1fcf1f09-0b85-45e2-b469-7bb97c5c7821.gif";
import img36 from "../../Assets/gallery/6_dd3c627c-c25c-4612-9122-ad1f47a88463 (1).gif";
import img37 from "../../Assets/gallery/7.gif";
import img38 from "../../Assets/gallery/6_9d264356-3177-48cc-9622-467a4e933b6d (1).gif";
import img39 from "../../Assets/gallery/6_4db88db7-6d26-47ad-a427-a764cbf142a1.gif";
import img40 from "../../Assets/gallery/6 (1).gif";
import img41 from "../../Assets/gallery/5_c1e027b6-5924-4fc2-ba26-47b2ee479493 (1).gif";
import img42 from "../../Assets/gallery/5_ba34d852-99fb-43a3-8ec3-c63958dd9cfd.gif";
import img43 from "../../Assets/gallery/6_9d264356-3177-48cc-9622-467a4e933b6d (1).gif";
import img44 from "../../Assets/gallery/6_4db88db7-6d26-47ad-a427-a764cbf142a1.gif";
import img45 from "../../Assets/gallery/6 (1).gif";
import img46 from "../../Assets/gallery/6 (1).gif";
import img47 from "../../Assets/gallery/5_ba34d852-99fb-43a3-8ec3-c63958dd9cfd.gif";
import img48 from "../../Assets/gallery/5_ba34d852-99fb-43a3-8ec3-c63958dd9cfd.gif";
import img49 from "../../Assets/gallery/5_b847f43f-23f8-43cd-84e1-d0663a302d10 (1).gif";
import img50 from "../../Assets/gallery/5_7465ada0-9570-4d91-a5ee-025289eb8ca2.gif";
import img51 from "../../Assets/gallery/4_dec62e0e-0c11-4898-b54a-c1f0447be399.gif";

// Our Cataloge
import cata1 from "../../Assets/catalog/Catlouge-1.webp";
import cata2 from "../../Assets/catalog/Catlouge-2.webp";
import cata3 from "../../Assets/catalog/Catlouge-3.webp";
import cata4 from "../../Assets/catalog/cataloge-4.webp";
import paf1 from "../../Assets/catalog/paf1.pdf";
import paf2 from "../../Assets/catalog/paf2.pdf";
import paf3 from "../../Assets/catalog/pdf3.pdf";
import paf4 from "../../Assets/catalog/pdf4.pdf";

const catalog = [cata1, cata2, cata3, cata4, paf1, paf2, paf3, paf4];

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img20,
];

export default function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);

  const openImage = (img) => {
    setSelectedImage(img);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const toggleZoom = () => {
    setIsZoomed((prevZoom) => !prevZoom);
  };

  const [activeTab, setActiveTab] = useState("gallery");
  return (
    <div className="Gly-container">
      <div className="heading mb-4 text-center">
        <span
          className={`gallery  ${activeTab === "gallery" ? "active" : ""}`}
          onClick={() => setActiveTab("gallery")}
          style={{ cursor: "pointer" }}
        >
          Our Gallery
        </span>
        <span
          className={` ${activeTab === "catalogue" ? "active" : ""}`}
          onClick={() => setActiveTab("catalogue")}
          style={{ cursor: "pointer" }}
        >
          Our Catalogue
        </span>
      </div>

      {/* Conditionally render Gallery or Catalogue based on the active tab */}
      {activeTab === "gallery" && (
        <div className="gallery inner-con">
          {/* {images.map((img, index) => (
            <div className="images" key={index}>
              <img src={img} alt={`gallery-img-${index}`} />
            </div>
          ))} */}
          {images.map((img, index) => (
            <div className="images" key={index}>
              <img
                src={img}
                alt={`gallery-img-${index}`}
                onClick={() => openImage(img)}
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}

          {selectedImage && (
            <div className="modal" onClick={closeImage}>
              <span className="close">&times;</span>
              {/* <Zoom> */}
              <img
                className={`modal-content ${isZoomed ? "zoomed" : ""}`}
                src={selectedImage}
                alt="full-screen"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent modal close on image click
                  toggleZoom();
                }}
              />
              {/* </Zoom> */}
            </div>
          )}
        </div>
      )}

      {activeTab === "catalogue" && (
        <div className="cataloge mt-5">
          <div className="catalogue-outer">
            {/* {catalog.map((item, key) => ( */}
            <div className="catalogue-item">
              <div className="left-box">
                <img src={cata1} alt="" />
              </div>
              <div className="right-box">
                <h4>Office & Institutional Furniture Catalogue</h4>
                <a href={paf1} download="Document.pdf">
                  <button>Click here to download</button>
                </a>
              </div>
            </div>

            <div className="catalogue-item box2">
              <div className="right-box">
                <h4>Sofa Catalogue</h4>
                <a href={paf2} download="Document.pdf">
                  <button>Click here to download</button>
                </a>
              </div>
              <div className="left-box">
                <img src={cata2} alt="" />
              </div>
            </div>

            <div className="catalogue-item">
              <div className="left-box">
                <img src={cata3} alt="" />
              </div>
              <div className="right-box">
                <h4>Work from home & Lifestyle Furniture</h4>
                <a href={paf3} download="Document.pdf">
                  <button>Click here to download</button>
                </a>
              </div>
            </div>
            <div className="catalogue-item box2">
              <div className="right-box">
                <h4>Company Profile</h4>
                <a href={paf4} download="Document.pdf" target="_black">
                  <button>Click here to download</button>
                </a>
              </div>
              <div className="left-box">
                <img src={cata4} alt="" />
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
      )}
    </div>
  );
}
