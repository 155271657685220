import styles from "../about/about.module.css";
import aboutCoverImg from "../../Assets/Images/Services/img1.webp";

const EndOfLease = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <div
          className={styles.heading}
          style={{  }}
        >
          {" "}
          Design Consultation{" "}
        </div> */}

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <h4>
            Design Consultation</h4>
            <p className={styles.aboutDesc}>
              Design consultation is a collaborative process where experts
              analyze client needs, preferences, and space requirements. Through
              in-depth discussions, designers offer tailored solutions,
              integrating aesthetics and functionality. This personalized
              approach ensures the creation of spaces that not only reflect the
              client's vision but also optimize the use of available resources.
            </p>
          </div>

          <div className={styles.video}>
            {/* <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video> */}
            <img src={aboutCoverImg} alt="" />
          </div>
        </div>

        {/* <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <h2 style={{ textAlign: "center", margin: 0, marginTop: "10px" }}>
              BOND CLEANING INCLUSIONS ( UNFURNISHED PROPERTY )
            </h2>

            <div>
              <h3 className={styles.subHeading}>General </h3>
              <ul>
                <li>
                  {" "}
                  Remove cobwebs, insect marks and small nests where we are
                  accessible{" "}
                </li>
                <li>
                  {" "}
                  Clean fans, Air Conditioners (a.c) and filters also wipe
                  around the air conditioners.
                </li>
                <li>
                  {" "}
                  Clean light fittings and switch boards (where they can be
                  safely removed and accessible)
                </li>
                <li> Spot clean walls, light switches and power points</li>
                <li> Clean skirting, and doors and frames.</li>
                <li>
                  {" "}
                  Clean drawers and cupboards including inside, doors and
                  cupboard tops and shelving
                </li>
                <li>
                  {" "}
                  Windows clean both sides (exclusions apply at heights and
                  accessibility)
                </li>
                <li> Vacuum sliding door tracks and clean tracks</li>
                <li>
                  {" "}
                  Clean standard blinds, cords and rails (spot clean and dust).
                </li>
                <li> Vacuum all the floors and carpets.</li>
                <li> Mopping floors.</li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default EndOfLease;
