import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  About,
  ContactUs,
  Testimonials,
  Bbq,
  Office,
  Spring,
  Home_clean,
  Carpet,
  Gardening,
  EndOfLease,
} from "./Pages";
import { Footer, Navbar } from "./Components";
import Servicess from "./Pages/Services_page/Servicess";
import Gallery from "./Pages/Gallery/Gallery";
import Categories from "./Pages/Categories/Categories.jsx";
import SingleProductDetails from "./Pages/SingleProductDetails/SingleProductDetails.jsx";
import SingleImgDetails from "./Components/SingleImgDetails/SingleImgDetails.jsx";
import ClientCheck from "./Pages/ClientCheck/ClientCheck.jsx";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/services" element={<Servicess />} />
          {/* <Route path="/goaland" element={<Bbq />} /> */}
          <Route path="/Rentals" element={<Office />} />
          <Route path="/preset" element={<EndOfLease />} />
          <Route path="/Spring-services" element={<Spring />} />
          <Route path="/2xcapital" element={<Home_clean />} />
          {/* <Route path="/PaisaGoa" element={<Gardening />} /> */}
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/jointventure" element={<Carpet />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/client" element={<ClientCheck />} />
          <Route
            path="/singleproductdetails"
            element={<SingleProductDetails />}
          />
          {/* <Route path="/SingleImgDetails/:sku" element={<SingleImgDetails />} /> */}
          <Route path="/SingleImgDetails" element={<SingleImgDetails />} />
        </Routes>
        {/* <Upper_Footer /> */}
        <Footer />
      </Router>
    </>
  );
}

export default App;
