import React from "react";
import SingleImgDetails from "../../Components/SingleImgDetails/SingleImgDetails";

export default function SingleProductDetails() {
  return (
    <div>
      <h3>Single Product Details</h3>
      {/* <SingleImgDetails /> */}
    </div>
  );
}
