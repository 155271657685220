import { useEffect, useState, useRef } from "react";
import styles from "./navbar.module.css";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import Logo from "../../Assets/Images/gjpnew.PNG";
import { useNavigate, NavLink } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast } from "react-toastify";
import { Value } from "sass";
import { type } from "@testing-library/user-event/dist/type";

import { RiArrowDropDownLine } from "react-icons/ri";

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 47)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 67)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="52"
        height="4"
        rx="2"
        transform="translate(294 57)"
        fill="#574c4c"
      />
    </g>
  </svg>
);

function Navbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleModal = () => {
    console.log("Click modal");
    setShow(true);
  };

  const [checkCalageroList, setCheckCalageroList] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos != 0;
      setIsScrolledDown(isScrollingDown);
      prevScrollPos = currentScrollPos;
      console.log(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    console.log("done");
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCheckCalageroList(false);
    }
  };

  useEffect(() => {
    if (checkCalageroList) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [checkCalageroList]);

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img
            src={Logo}
            alt="logo"
            className={styles.logo}
            onClick={() => navigate("/")}
          />
        </div>

        <div className={styles.menu_icon} onClick={handleShowNavbar}>
          <Hamburger />
        </div>

        <div className={`nav-header ${styles.navheader}`}>
          <ul className="d-flex gap-3 fw-bold text-black list-unstyled mt-4">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : `${styles.headtext}`
                }
                onMouseEnter={() => setCheckCalageroList(false)}
              >
                Home
              </NavLink>
            </li>

            {/* <div className="dropdown">
              <button
                className=" dropdown-toggle fw-bold bg-transparent"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
      
                Categories
             
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "officeFurniture" },
                      })
                    }
                  >
                    Office Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "metalstorage" },
                      })
                    }
                  >
                    Metal Storage
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "officeChair&sofa" },
                      })
                    }
                  >
                    Office Chairs & Sofas
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "compactors" },
                      })
                    }
                  >
                    Compactors
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "institutionalfurniture" },
                      })
                    }
                  >
                    Institutional Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "hotel&hostel" },
                      })
                    }
                  >
                    Hotel & Hostel Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "laboratorufurniture" },
                      })
                    }
                  >
                    Laboratory Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "homefurniture" },
                      })
                    }
                  >
                    Home Furniture
                  </button>
                </li>
              </ul>
            </div> */}

            <li
              className={({ isActive }) =>
                isActive ? `${styles.activeLink}` : `${styles.headtext}`
              }
              onClick={() => setCheckCalageroList(true)}
            >
              Categories
              <RiArrowDropDownLine size={25} />
            </li>

            {/* Apply NavLink for each menu item */}
            <li>
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : `${styles.headtext}`
                }
                onMouseEnter={() => setCheckCalageroList(false)}
              >
                Services
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/gallery"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : `${styles.headtext}`
                }
                onMouseEnter={() => setCheckCalageroList(false)}
              >
                Gallery
              </NavLink>
            </li>

            {/* About Link: Change color and border when active */}
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : `${styles.headtext}`
                }
                onMouseEnter={() => setCheckCalageroList(false)}
              >
                About Us
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : `${styles.headtext}`
                }
                onMouseEnter={() => setCheckCalageroList(false)}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>

        {/* for mobile */}
        <div
          className={`${styles.nav_elements} ${showNavbar && styles.active}`}
        >
          <div
            className={styles.closeIcon}
            onClick={() => setShowNavbar(!showNavbar)}
          >
            <FaTimes />
          </div>

          <div className={styles.btnContainer}>
            <ul className="list-unstyled fw-bold">
              <li
                className="mt-2"
                onClick={() => {
                  navigate("/");
                  setShowNavbar(false);
                }}
              >
                Home{" "}
              </li>
              {/* <li
                className="mt-2"
                onClick={() => {
                  navigate("/Categories");
                  setShowNavbar(false);
                }}
              >   
                Categories
              </li> */}

              <li className="mt-2">
                <div className="dropdown">
                  <button
                    className="dropdown-toggle fw-bold bg-transparent"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Categories
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "officeFurniture" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Office Furniture
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "metalstorage" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Metal Storage
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "officeChair&sofa" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Office Chairs & Sofas
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "compactors" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Compactors
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "institutionalfurniture" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Institutional Furniture
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "hotel&hostel" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Hotel & Hostel Furniture
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "laboratorufurniture" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Laboratory Furniture
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => {
                          navigate("/Categories", {
                            state: { type: "homefurniture" },
                          });
                          setShowNavbar(false);
                        }}
                      >
                        Home Furniture
                      </button>
                    </li>
                  </ul>
                </div>
              </li>

              <li
                className="mt-2"
                onClick={() => {
                  navigate("/services");
                  setShowNavbar(false);
                }}
              >
                Services
              </li>
              <li
                className="mt-2"
                onClick={() => {
                  navigate("/gallery");
                  setShowNavbar(false);
                }}
              >
                Gallery
              </li>
              <li
                className="mt-2"
                onClick={() => {
                  navigate("/about");
                  setShowNavbar(false);
                }}
              >
                About Us
              </li>
              {/* <li
                className="mt-2"
                onClick={() => {
                  navigate("/career");
                  setShowNavbar(false);
                }}
              >
                Career
              </li> */}
              <li
                className="mt-2"
                onClick={() => {
                  navigate("/contact");
                  setShowNavbar(false);
                }}
              >
                Contact Us
              </li>
            </ul>
          </div>
        </div>

        {showNavbar && (
          <div
            className={styles.sidebarOverlay}
            onClick={() => setShowNavbar(!showNavbar)}
          />
        )}
      </div>

      {checkCalageroList && (
        <div
          className="catogoreis"
          onMouseLeave={() => setCheckCalageroList(false)}
        >
          <div className="cat">
            <div>
              <Link
                className="link"
                to="/Categories"
                state={{ type: "officeFurniture" }}
                onClick={() => setCheckCalageroList(false)}
              >
                Office Furniture
              </Link>
              <ul>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "ExecutiveDesk",
                    }}
                  >
                    Executive cabin Desks
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "standardTable",
                    }}
                  >
                    Standard Tables
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "conferenceAndMeetingTable",
                    }}
                  >
                    Conference & Meeting Tables
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "workstationOpendesking",
                    }}
                  >
                    Workstation (Open Desk)
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "workstationCubicless",
                    }}
                  >
                    Workstation (Cubicles)
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "receptionCounter",
                    }}
                  >
                    Reception Counters
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "officeFurniture",
                      selectedCategory: "storageCabinets",
                    }}
                  >
                    Storage Cabinets
                  </Link>
                </li>
              </ul>
            </div>
            {/* Metal Storage */}
            <div>
              <Link
                className="link"
                to="/Categories"
                state={{ type: "metalstorage" }}
                onClick={() => setCheckCalageroList(false)}
              >
                Metal Storage
              </Link>
              <ul>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "metalstorage",
                      selectedCategory: "ExecutiveDesk",
                    }}
                  >
                    Metal Almirah
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "metalstorage",
                      selectedCategory: "standardTable",
                    }}
                  >
                    Lockers
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "metalstorage",
                      selectedCategory: "conferenceAndMeetingTable",
                    }}
                  >
                    Filing Cabinets
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "metalstorage",
                      selectedCategory: "workstationCubicless",
                    }}
                  >
                    Book Shelves
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "metalstorage",
                      selectedCategory: "receptionCounter",
                    }}
                  >
                    Slotted Angle Racks
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Institutional Furniture */}
          <div className="cat">
            <div>
              <Link
                className="link"
                to="/Categories"
                state={{ type: "institutionalfurniture" }}
                onClick={() => setCheckCalageroList(false)}
              >
                Institutional Furniture
              </Link>
              <ul>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "institutionalfurniture",
                      selectedCategory: "ExecutiveDesk",
                    }}
                  >
                    Smart Desk & Dual Desks
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "institutionalfurniture",
                      selectedCategory: "standardTable",
                    }}
                  >
                    Computer Lab And Library Desks
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "institutionalfurniture",
                      selectedCategory: "conferenceAndMeetingTable",
                    }}
                  >
                    Canteen And Cafeteria Furniture
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "institutionalfurniture",
                      selectedCategory: "workstationCubicless",
                    }}
                  >
                    Library Racks
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "institutionalfurniture",
                      selectedCategory: "Computertbale",
                    }}
                  >
                    Computer Table
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "institutionalfurniture",
                      selectedCategory: "receptionCounter",
                    }}
                  >
                    Podium
                  </Link>
                </li>
              </ul>
            </div>

            {/* Laboratory Furniture */}
            <div>
              <Link
                className="link"
                to="/Categories"
                state={{ type: "laboratorufurniture" }}
                onClick={() => setCheckCalageroList(false)}
              >
                Laboratory furniture
              </Link>
              <ul>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "laboratorufurniture",
                      selectedCategory: "ExecutiveDesk",
                    }}
                  >
                    Science Labs
                  </Link>
                </li>
                <li onClick={() => setCheckCalageroList(false)}>
                  <Link
                    className="linktxt"
                    to="/Categories"
                    state={{
                      type: "laboratorufurniture",
                      selectedCategory: "standardTable",
                    }}
                  >
                    Commercial Labs
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Other Categories */}
          <div className="cat">
            <ul>
              <li onClick={() => setCheckCalageroList(false)}>
                <Link
                  className="link"
                  to="/Categories"
                  state={{ type: "officeChair&sofa" }}
                  onClick={() => setCheckCalageroList(false)}
                >
                  OFFICE CHAIRS & SOFAS
                </Link>
              </li>
              <li onClick={() => setCheckCalageroList(false)}>
                <Link
                  className="link"
                  to="/Categories"
                  state={{ type: "compactors" }}
                  onClick={() => setCheckCalageroList(false)}
                >
                  COMPACTORS
                </Link>
              </li>
              <li onClick={() => setCheckCalageroList(false)}>
                <Link
                  className="link"
                  to="/Categories"
                  state={{ type: "hotel&hostel" }}
                >
                  HOTEL & HOSTEL FURNITURE
                </Link>
              </li>
              <li onClick={() => setCheckCalageroList(false)}>
                <Link
                  className="link"
                  to="/Categories"
                  state={{ type: "homefurniture" }}
                >
                  HOME FURNITURE
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      <ToastContainer />
    </nav>
  );
}

export default Navbar;
