import React from "react";
import "./AllCardCategories.scss";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function AllCardCategories({ items }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="outercat">
        <div className="innerCot mt-2">
          <div className="productMaindiv  mt-3">
            {items.map((item, index) => (
              <div key={index} className="products">
                <Link to={`/SingleImgDetails`} state={{ item }}>
                  <div className="imgdiv">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="details">
                    <h6> {item.name}</h6>
                    {item.sku.length > 0 && <p>SKU : {item.sku} </p>}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
