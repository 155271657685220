import styles from "./about.module.css";
import "./About.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// imagess
import teamImg from "../../Assets/Images/Final_img/10.jpg";
import aboutCoverImg from "../../Assets/Images/About/9.gif";
import Teammember from "../../Components/Teammember/Teammember";
import tempusimg from "../../Assets/about/tempus.webp";
import Roadmap from "../../Assets/about/roadmap.png";
import visionimg from "../../Assets/about/visionnew.JPG";
import varadraj from "../../Assets/Images/About/vardaji.webp";
import mahesh from "../../Assets/Images/About/mahesh.webp";
import img1 from "../../Assets/about/img1.webp";
import img2 from "../../Assets/about/img2.webp";
import img3 from "../../Assets/about/img3.webp";
import img4 from "../../Assets/about/img4.webp";
import img5 from "../../Assets/about/img5.webp";
import img6 from "../../Assets/about/img6.webp";
import img7 from "../../Assets/about/img7.webp";
import img8 from "../../Assets/about/img8.webp";
// certificates
import cert1 from "../../Assets/about/AIOTA--ALL-INDIA-OCCUPATIONAL-THERAPISTS-ASSOCIATION.jpg";
import cert2 from "../../Assets/about/BIFMA.jpg";
import cert3 from "../../Assets/about/CERTIFICATION3.webp";
import cert4 from "../../Assets/about/GREEN-PRO.jpg";
import cert5 from "../../Assets/about/Green-Guard.jpg";
// import cert6 from "../../Assets/about/Green-Guard.webp";
import cert7 from "../../Assets/about/ISO-14001-2015.jpg";
import cert8 from "../../Assets/about/ISO-45001-2018.jpg";
import cert9 from "../../Assets/about/ISO-9001-2015-UKCERT-FINAL-COPY.jpg";
// import cert10 from "../../Assets/about/about-us-1.webp";
// import cert11 from "../../Assets/about/certificat1.webp";
import { Autoplay } from "swiper/modules";
import { BiColor, BiFontColor } from "react-icons/bi";
import { useEffect } from "react";
// import cert12 from "../../Assets/about/img1.webp";
const certificas = [
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  // cert6,
  cert7,
  cert8,
  cert9,
  // cert11,
];
const About = () => {
  const settings = {
    dots: true,
    dotStyles: BiFontColor("red"),
    infinite: true,
    Autoplay: true,
    autoplaySpeed: 3000,
    Autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Screen width under 600px
        settings: {
          slidesToShow: 2, // Show 1 slide
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Screen width under 600px
        settings: {
          slidesToShow: 1, // Show 1 slide
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.wrapper}>
        <h2 className=" text-center mt-3">About Tempus</h2>

        <div className="about-tem row">
          <div className="left-about-img col-md-5 mt-5 text-center">
            <img src={tempusimg} alt="" />
          </div>
          <div className="right-contect col-md-7 px-5 mt-3">
            <p className={styles.patatext}>
              We are a fast growing and one of the most promising office and
              institutional furniture manufacturing companies in India. In the
              experienced individuals from the furniture fraternity and just the
              right infrastructure, that gets renewed from time to time to cope
              up with ever changing demands of the market, we have just the
              right ingredients to call ourselves progressive and foresighted.
              Our state of art manufacturing facilities at two major cities of
              India i.e. Bangalore in South and Indore in Central India with
              modern plant and machinery enable us to cater to the requirements
              of both panel based furniture and Metal Furniture too. Our USP
              also lies in the fact that with all the in house facilities,
              designing or customization is never a limitation for us. Being
              true to our name Tempus, which in Latin stands for time and
              Finnish, we are committed to deliver in time with absolutely no
              compromises on quality and Finnish. Our Self confidence can also
              be understood from the fact that we are always open for any kind
              of third party inspection at any of our manufacturing facilities,
              which is often a pre-requisite of many buyers. In a very short
              span of time we have not just successfully completed some renowned
              projects in India but are already exporting our furniture abroad.
              From here on we look at making ourselves one of the most reputed
              Indian office and institutional furniture suppliers in times to
              come.
            </p>
          </div>
        </div>

        <div className={styles.roadmap}>
          <img src={Roadmap} alt="roadmap" />
        </div>

        <div className="tem-vision row">
          <div className="vision-left col-md-6 p-5 m-auto">
            <h2 className="mb-2">Our Vision & Mission</h2>
            <p>
              In a very short span of time we have not just successfully
              completed some renowned projects in India but are already
              exporting our furniture abroad. From here on we look at making
              ourselves one of the most reputed Indian modular furniture
              suppliers in times to come and we look forward to strengthening
              our economy by being an active participant of the MAKE IN INDIA
              concept. <br />
              <br />
              <b>
                We are committed to delivering just the right quality at just
                the right time.
              </b>
            </p>
          </div>
          <div className="vision-img col-md-6 text-center">
            <img src={visionimg} alt="" className="h-100" />
          </div>
        </div>

        <div className="directors mt-5">
          <div className=" text-center">
            <h4 className="fw-bold">Director's Message</h4>
          </div>

          <div className="varadraj border border-secondary-subtl row mt-5">
            <div className="col-md-4 text-center">
              <img
                src={varadraj}
                alt="Responsive Image"
                className="mt-5"
                style={{
                  width: "250px",
                }}
              />
            </div>
            <div className="varad-con col-md-8 m-auto px-4">
              <h3 className="px-3 mt-2">Varadraj KP</h3>

              <p className="text-star p-3" style={{ textAlign: "justify" }}>
                From a humble start in 2006 to the day when I am penning down my
                opinion on the business, the one thing that I have firmly come
                to believe in is the fact that there's never a shortcut to
                success. One's hardwork and sincerity may take time in paying
                off but it does, eventually. Our perseverance and resilience
                have been the key to the milestones we have achieved in all
                these years. The acceptance that we receive from our valued
                customers keeps us going and we strive to continue walking on
                the path of DADS-Determination, Acceptance, Dedication &
                Sincerity. We are "Determined" to reach our goal of making
                Tempus a global brand in Modular furniture, for which we work in
                close association with the new generation to ensure "Acceptance"
                by the volatile needs of the market without ever compromising
                with our core principle of "Dedication & Sincerity".
              </p>
            </div>
          </div>

          <div className="mahesh row mt-5 border border-secondary-subtl ">
            <div className="mahesh-con col-md-8 m-auto px-5 ">
              <h3>Mahesh Jain</h3>
              <p className="text " style={{ textAlign: "justify" }}>
                From a humble start in 2006 to the day when I am penning down my
                opinion on the business, the one thing that I have firmly come
                to believe in is the fact that there's never a shortcut to
                success. One's hardwork and sincerity may take time in paying
                off but it does, eventually. Our perseverance and resilience
                have been the key to the milestones we have achieved in all
                these years. The acceptance that we receive from our valued
                customers keeps us going and we strive to continue walking on
                the path of DADS-Determination, Acceptance, Dedication &
                Sincerity. We are "Determined" to reach our goal of making
                Tempus a global brand in Modular furniture, for which we work in
                close association with the new generation to ensure "Acceptance"
                by the volatile needs of the market without ever compromising
                with our core principle of "Dedication & Sincerity".
              </p>
            </div>

            <div className="col-md-4 text-center ">
              <img
                src={mahesh}
                className=""
                alt="Responsive Image"
                style={{
                  width: "250px",
                }}
              />
            </div>
          </div>
        </div>

        {/* team member */}
        <div className="teamCon mt-5">
          <h2 className="text-center">Meet Our Team</h2>

          <Teammember />
        </div>

        <div>
          <h3 className="text-center mt-5">Glimpses Of Factory</h3>

          <div
            id="carouselExampleAutoplaying"
            className="carousel slide mt-3"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                {/* <div className="d-block w-100"></div> */}
                <img src={img1} className="w-50" alt="..." />
                <img src={img2} className="w-50" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={img3} className="w-50" alt="..." />
                <img src={img4} className="w-50" alt="..." />
              </div>

              <div className="carousel-item">
                <img src={img7} className="w-50" alt="..." />
                <img src={img8} className="w-50" alt="..." />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div>
          <div className="carousel-container mt-5 mb-5">
            <h3 className="text-center ">OUR CERTIFICATION</h3>
            {/* <style>{dotStyles}</style> */}
            <div className={styles.Slider}>
              <Slider {...settings}>
                {certificas.map((cert, index) => (
                  <div className={styles.box} key={index}>
                    <img
                      src={cert}
                      alt={`Certificate ${index + 1}`}
                      className=""
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
