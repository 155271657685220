import React from "react";
import "./Carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// images
import man1 from "../../Assets/testimonial/OIP (1).jpeg";
import man2 from "../../Assets/testimonial/OIP (2).jpeg";
import man3 from "../../Assets/testimonial/OIP.jpeg";
import logo6 from "../../Assets/ourClient/1631322819442.jpg";
import apollo from "../../Assets/ourClient/apolo.svg";
//done
export const Carousel = () => {
  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div>
        <svg
          className={className}
          onClick={onClick}
          style={{ transform: "rotate(180deg)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
        >
          <path
            d="M2 2L12 12L2 22"
            stroke="#52E6C2"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  };

  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div>
        <svg
          className={className}
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
        >
          <path
            d="M2 2L12 12L2 22"
            stroke="#52E6C2"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  };

  var settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          // prevArrow: null,
          // nextArrow: null,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      client_name: "Dr. Piyush Yadav ",
      work: " ( Dycine Pharma Indore, Pithampur)",
      client_message:
        "Absolute value for money, good services and prompt response to queries, we are very happy to have found Tempus, as our one stop destination for all furniture needs.",
      image: logo6,
    },
    {
      client_name: "Rajeev Nair",
      work: "(Apollo hospital indore)",
      client_message: `We bought complete furniture for Synergy Apollo from Tempus Furniture &amp; have had very good experience working with them, all our customized needs of hospital furniture were taken care of perfectly, thumbs up to them!.`,
      image: apollo,
    },
    // {
    //   client_name: "Kathryn brooke",
    //   client_message: `.Great servicec , value for money and my house smelled wonderful for days.`,
    //   image: man3,
    //   image: man3,
    // },
  ];

  return (
    <div className="slider_carousel">
      <Slider {...settings}>
        {testimonials?.map((el, i) => {
          return (
            <div className="slider_item">
              <figure className="main-container">
                <div className="author">
                  <h5>{el?.client_name}</h5>
                  <p>{el.work}</p>

                  <figcaption>
                    <blockquote>{el?.client_message}</blockquote>
                    <div className="arrow"></div>
                  </figcaption>
                </div>

                <img src={el?.image} alt="sq-sample10" />
              </figure>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
