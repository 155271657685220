import styles from "./testimonials.module.css";
import { Carousel } from "../../Components";

function Testimonials() {
  return (
    <>
      <div className={styles.testimonialWrapper}>
        <div className={styles.testimonialhead}>
          <h3 className={styles.heading}>Testimonials</h3>
        </div>

        <Carousel />
      </div>
    </>
  );
}

export default Testimonials;
