import React from "react";
import styles from "./footer.module.css";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import LogoImage from "../../Assets/Tempus_whitecrop.png";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../../Assets/Images/gjpnew.PNG";

const Footer = () => {
  return (
    <div className={styles.sb_footer}>
      <div className={styles.sb_footer_link}>
        <div className={styles.sub_footer_div_link}>
          <h4>MENU</h4>

          <a href="/home">
            <p>Home</p>
          </a>
          <a href="/about">
            <p>About Us</p>
          </a>
          <a href="/Contact">
            <p>Contact Us</p>
          </a>
          <a href="/gallery">
            <p>Gallery</p>
          </a>

          <img src={logo} className={styles.footerImg} alt="" />
        </div>

        <div className={styles.sub_footer_div_link}>
          <h4>SERVICES</h4>

          <a href="/preset">
            <p>Design Consultation</p>
          </a>
          <a href="/jointventure">
            <p>Tailor Made Furniture</p>
          </a>
          <a href="/2xcapital">
            <p>Delivery & Installation</p>
          </a>
          <a href="/Rentals">
            <p>Warranty & Maintenance</p>
          </a>
        </div>

        <div className={styles.sub_footer_div_link}>
          <h4>CONTACT US</h4>

          <div className={styles.footer_sub_add}>
            <div className={styles.social}>
              {/* <BiLogoGmail size={20} /> */}

              <p>info@tempusfurniture.com</p>
              <p className="mt-1">mahesh@tempusfurniture.com</p>
              <p className="mt-1">products@vdecoindia.com</p>
            </div>

            <div className={styles.social}>
              <a
                className={styles.linkcolor}
                href="https://wa.me/+919009988770?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
                target="_blank"
              >
                <FaWhatsapp size={20} />

                <p>+91 9009988770</p>
              </a>
            </div>
          </div>

          <div className={styles.socialmedia}>
            <p>
              {" "}
              <a
                className=""
                href="https://www.instagram.com/tempus_furniture/"
                target="_black"
              >
                <FaInstagram size={30} />
              </a>{" "}
            </p>
            <p>
              <a
                href="https://www.facebook.com/TempusFurnitureSolutions/"
                target="_black"
              >
                <FaFacebook size={30} />
              </a>{" "}
            </p>

            <p>
              {" "}
              <a
                className=""
                href="https://www.youtube.com/@tempusfurniture7408"
                target="_black"
              >
                <FaYoutube size={30} />
              </a>{" "}
            </p>
            <p>
              {" "}
              <a
                className=""
                href="https://www.linkedin.com/company/tempus-furniture-solutions-llp/"
                target="_black"
              >
                <FaLinkedin size={30} />
              </a>{" "}
            </p>
          </div>
        </div>
      </div>

      <hr className={styles.hr} />

      <div className={styles.sb__footer_below}>
        <div className={styles.sb__footer_copyright}>
          <p>
            © {new Date().getFullYear()}{" "}
            <span style={{ fontWeight: "600" }}>Tempus Furniture</span>.
          </p>

          <a href="https://www.ehawkers.co.in/" target="_blanck">
            <p>
              Powered by : <b className="text-bold">e-Hawkers Marketing LLP,</b>{" "}
              Indore.
            </p>
          </a>
        </div>

        <div className={styles.sb_footer_below_links}>
          <a href="">
            <div>
              <p>Term & Conditions</p>
            </div>
          </a>
          <a href="">
            <div>
              <p>Privacy</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
