import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./CategoriesSlider.scss";

// img
import officefurniture from "../../Assets/categories slider/Catlouge_1.webp";
import officechairsofa from "../../Assets/categories slider/Catlouge_2.webp";
import intitutionalfurniture from "../../Assets/categories slider/Catlouge_3.webp";
import laboratrory from "../../Assets/categories slider/Catlouge_4.webp";
import metal from "../../Assets/categories slider/Catlouge_5.webp";
import comactor from "../../Assets/categories slider/Catlouge_6.webp";
import hotel from "../../Assets/categories slider/Catlouge_7.webp";
import home from "../../Assets/categories slider/Catlouge_8.webp";
import { Navigate, useNavigate } from "react-router-dom";

// Corresponding names for each furniture item
const furnitureNames = [
  "Office Furniture",
  "Office Chairs & Sofas",
  "Institutional Furniture",
  "Laboratory Furniture",
  "Metal Storage",
  "Compactors",
  "Hotel Furniture",
  "Home Furniture",
];

const furniture = [
  officefurniture,
  officechairsofa,
  intitutionalfurniture,
  laboratrory,
  metal,
  // cert6,
  comactor,
  hotel,
  home,
  // cert11,
];
export default function CategoriesSlider() {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Screen width under 600px
        settings: {
          slidesToShow: 4, // Show 1 slide
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Screen width under 600px
        settings: {
          dots: false,

          slidesToShow: 2, // Show 1 slide
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <div className="carousel-container mt-5 mb-5">
          <h2 className="text-center ">Categories</h2>
          <p className="text-center mt-2">
            Your one-stop Solution for stylish furniture and decor, delivering
            the latest trends exclusively to Perth.
          </p>
          {/* <style>{dotStyles}</style> */}
          <div className="sliderBox mt-4">
            <Slider {...settings}>
              {furniture.map((cert, index) => (
                <div className="singlebox px-2" key={index}>
                  <img
                    src={cert}
                    alt={`Certificate ${index + 1}`}
                    className=""
                  />
                  <h6 className="text-center mt-2">{furnitureNames[index]}</h6>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
