import styles from "./home.module.css";
import { Services } from "../../Components";
import { useNavigate } from "react-router-dom";
import livingRoom from "../../Assets/Images/Home/tempus.webp";
import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Testimonials from "../Testimonial/Testimonials";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import FAQ from "../../Components/FAQ/FAQ";
import HomeCrousel from "../../Components/HomeCrousel/HomeCrousel";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "../../Components/AboutUs/AboutUs";
import NavCategories from "../../Components/NavCategories/NavCategories";
//done
import card1 from "../../Assets/CategoriesImg/product cards/Untitled-1 (1).png";
import card2 from "../../Assets/CategoriesImg/product cards/Untitled-1.png";
import card3 from "../../Assets/CategoriesImg/product cards/Untitled-2.png";
import card4 from "../../Assets/CategoriesImg/product cards/Untitled-3.png";
import card5 from "../../Assets/CategoriesImg/product cards/Untitled-4.png";
import card6 from "../../Assets/CategoriesImg/product cards/Untitled-5.png";

// our client logo
import logo5 from "../../Assets/Client/8.jpeg";
import logo9 from "../../Assets/Client/15.png";
import logo14 from "../../Assets/Client/37.png";
import logo11 from "../../Assets/Client/11.png";
import logo17 from "../../Assets/Client/4.png";
import logo28 from "../../Assets/Client/7.png";
import logo34 from "../../Assets/Client/30.jpeg";

import CategoriesSlider from "../../Components/CategoriesSlider/CategoriesSlider";

const data = [
  {
    title: "What material do you use in making furniture?",
    description:
      "We deal in all sorts of engineered wood namely Ply, MDF, HDHMR and PLPB. Besides, we work in both sheet metal and MS pipes too. At certain places we use SS, Glass and Fabric too. Sofa structures are made both in Metal and Wood.",
  },
  {
    title: "How is factory made furniture better than carpentry work?",
    description:
      "Furniture at our factory is made with advance technology driven German & Japanese machinery which ensures precision and finesse with all the edges being subtle and smooth,  We have Hot Press Machine for pasting laminate which works with high pressure and temperature,  Hassel free process for the customer as factory made furniture involves no mess at the site, The ready to install material is delivered in knock down state and the only job done at site is of Installation of parts.",
  },
  {
    title: "Where in India do you supply your furniture?",
    description:
      "We have dual manufacturing facilities, 1 at Bangalore and other at Indore and we do pan India delivery.",
  },
  {
    title: "Do you customize designs as per client’s need?",
    description:
      "Yes, absolutely! Infact bespoke furniture is our USP and we can customize designs as per our client’s need.",
  },
  {
    title: "What is the warranty period offered on your products?",
    description:
      "A Warranty period of 1 year from the date of delivery on manufacturing defects is offered by us.",
  },
  {
    title: "What is the turnaround time of an order?",
    description:
      "The exact turnaround time depends on the designs, quantum of work and the availability of material in stock however, in general we take",
  },
];

function Home() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const closeBtn = () => {
    setShow(false);
  };

  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "best1_cleaners",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    fullName: "",
  });
  //done
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace these with your actual email service, template ID, and user ID
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    console.log("serviceId: ", serviceId);
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    console.log("templateId: ", templateId);

    try {
      await emailjs.send(serviceId, templateId, formData, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      });

      // Clear form data after successful submission
      setFormData({
        email: "",
        phoneNumber: "",
        message: "",
        fullName: "",
      });
      toast.success("Email send Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <>
      {/* navbar  */}
      {/* <NavCategories /> */}

      <div className={styles.wrapper} style={{ position: "relative" }}>
        {/* crousel */}
        <HomeCrousel />

        {/* LIST OF SERVICES */}
        <div className={styles.servicesHead}> Our Services </div>
        <Services />

        <div>
          <CategoriesSlider />
        </div>

        {/* SERVICES DESCRIPTION */}
        <div className={styles.aboutServices}>
          <div className={styles.servicesLeft}>
            <span className={styles.leftHead}> About Tempus</span>

            <span className={styles.leftDesc}>
              We are a fast growing and one of the most promising office and
              institutional furniture manufacturing companies in India. In the
              experienced individuals from the furniture fraternity and just the
              right infrastructure, that gets renewed from time to time to cope
              up with ever changing demands of the market, we have just the
              right ingredients to call ourselves progressive and foresighted.
              Our state of art manufacturing facilities at two major cities of
              India i.e. Bangalore in South and Indore in Central India with
              modern plant and machinery enable us to cater to the requirements
              of both panel based furniture and Metal Furniture too.
              <details
                className="mt-3  m-auto"
                onClick={() => {
                  navigate("/about");
                  window.scrollTo(0, 0);
                }}
              >
                <summary className={styles.readmorebtn}>Read more</summary>
                <p>
                  Our USP also lies in the fact that with all the in house
                  facilities, designing or customization is never a limitation
                  for us. Being true to our name Tempus, which in Latin stands
                  for time and Finnish, we are committed to deliver on time with
                  absolutely no compromises on quality and finish. Our
                  self-confidence is also reflected in the fact that we are
                  always open for any kind of third-party inspection at any of
                  our manufacturing facilities, which is often a pre-requisite
                  for many buyers.
                </p>
                <p>
                  In a very short span of time, we have not only successfully
                  completed some renowned projects in India but are also
                  exporting our furniture abroad. From here, we aim to establish
                  ourselves as one of the most reputed Indian office and
                  institutional furniture suppliers in the future.
                </p>
              </details>
            </span>
          </div>

          <div className={styles.serviceImageContainer}>
            <img
              src={livingRoom}
              className={styles.aboutServicesImg}
              alt="services-image"
            />
          </div>
        </div>
      </div>

      <AboutUs />

      <div className="mt-5 cardproduct">
        <h2 className="text-center mb-3">Why Us?</h2>
        <div className="row">
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card1} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card2} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card3} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card4} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card5} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card6} alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className={styles.logocontainer}>
        <h3 className="text-center mb-3">Our Clientele</h3>
        <div className={styles.ourclient}>
          <div className={styles.logodiv}>
            <img src={logo9} alt="Logo 1" />
          </div>
          <div className={styles.logodiv}>
            <img src={logo5} alt="Logo 2" />
          </div>
          <div className={styles.logodiv}>
            <img src={logo11} alt="Logo 3" />
          </div>
          <div className={styles.logodiv}>
            <img src={logo14} alt="Logo 4" />
          </div>
          <div className={styles.logodiv}>
            <img src={logo17} alt="Logo 5" />
          </div>
          <div className={styles.logodiv}>
            <img src={logo28} alt="Logo 6" />
          </div>
          <div className={styles.logodiv}>
            <img src={logo34} alt="Logo 7" />
          </div>
        </div>
        <button
          className={styles.buttonCheck}
          onClick={() => {
            navigate("/client");
            window.scrollTo(0, 0);
          }}
        >
          View All
        </button>
      </div>

      <Testimonials />

      {/* faq section  */}
      <div className={styles.faqContainer}>
        <FAQ data={data} />
      </div>

      <ContactUs />

      <div
        id="myModal"
        className="modal"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={closeBtn}>
            &times;
          </span>
          <form className={styles.contact_form} onSubmit={handleSubmit}>
            <span className={styles.formHeader}>SEND A MESSAGE</span>

            <div className={styles.input_group}>
              <div className={styles.formField}>
                <div className={styles.label}>Full Name</div>
                <input
                  className={styles.formInput}
                  type="text"
                  id="fullName"
                  onChange={handleInputChange}
                  name="fullName"
                  required
                />
              </div>

              <div className={styles.formField}>
                <div className={styles.label}>Email</div>
                <input
                  required
                  className={styles.formInput}
                  type="email"
                  id="email"
                  onChange={handleInputChange}
                  name="email"
                />
              </div>
            </div>
            <div className={styles.formField}>
              <div className={styles.label}>Phone</div>
              <input
                required
                className={styles.formInput}
                type="tel"
                id="phone"
                name="phoneNumber"
                onChange={handleInputChange}
              />
            </div>

            <div className={styles.formField}>
              <div className={styles.label}> Job Description </div>
              <textarea
                className={styles.formInput}
                id="jobDescription"
                name="message"
                rows="4"
                onChange={handleInputChange}
                required
              />
            </div>

            <button className={styles.submitBtn} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* <Footer /> */}
      <ToastContainer />
    </>
  );
}

export default Home;
